import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { reviewActions } from './reviewState';
import { getDatas, getDataById } from '../../../services/base.service';

function* getReviewDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.REVIEW.REVIEW, payload);
    yield put(reviewActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.REVIEW.REVIEW_ID, payload);
    yield put(reviewActions.getDataSuccess(result));
  } catch (_) { }
}

function* reviewSaga() {
  yield takeLatest('review/getDatas', getReviewDatas);
  yield takeLatest('review/getData', getData);
}

export default reviewSaga;

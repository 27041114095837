import { call, put, takeLatest } from 'redux-saga/effects';
import { illustrationActions } from './illustrationState';
import { REST_API } from '../../../constants/serverUrls';
import { getDataById, uploadFiles, updateData } from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.ILLUSTRATION.ILLUSTRATION_ID, payload);
    yield put(illustrationActions.getDataSuccess(result));
  } catch (_) { }
}

function* save({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'imageFiles') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    for (let i = 0; i < payload.imgForm.images.length; i += 1) {
      const tmpData = payload.imgForm.images[i];
      if (tmpData.isNew) {
        const files = tmpData[0];
        formData.append(`imageFiles_${tmpData.id}`, files);
      }
    }

    const result = yield call(uploadFiles, REST_API.SYSTEM_SETTING.ILLUSTRATION.ILLUSTRATION, formData);
    yield put(illustrationActions.saveDataSuccess(result));
  } catch (_) { }
}

function* compImgUpload({ payload }) {
  try {
    const result = yield call(updateData, REST_API.SYSTEM_SETTING.ILLUSTRATION.ILLUSTRATION_ID, payload);
    yield put(illustrationActions.compImgUploadSuccess(result));
  } catch (_) { }
}

function* illustrationSaga() {
  yield takeLatest('illustration/getData', getData);
  yield takeLatest('illustration/saveData', save);
  yield takeLatest('illustration/compImgUpload', compImgUpload);
}

export default illustrationSaga;

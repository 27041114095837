import React, { useEffect } from 'react';
import {
  Box, DialogActions, Button, DialogContent,
  TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '../../atoms/Dialog';
import { FormTitle, TextArea, FormErrorText } from '../../atoms/Base';
import Dropzone from '../../atoms/Dropzone';

export default function FileUploadDialog(props) {
  const [data, setData] = React.useState({ introduction: '', title: '' });
  const [errorMsg, setErrorMsg] = React.useState('');

  const {
    isOpen, onClose, onClick, newId, isNeedIntroduction, isNeedTitle,
    item,
  } = props;

  useEffect(() => {
    if (isOpen) {
      setData(item || { introduction: '', title: '' });
      setErrorMsg('');
    }
  }, [isOpen]);

  const onUpload = (imgData) => {
    setData({ ...data, ...imgData, fileName: imgData[0].name });
    setErrorMsg('');
  };

  return (
    <Dialog title="ファイルアップロード" isOpen={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent dividers>
        <Box>
          {!item && (
            <>
              <Dropzone onChange={onUpload} />
              <FormErrorText>{errorMsg}</FormErrorText>
            </>
          )}
          {item && (
            <Box sx={{ overflowY: 'auto', textAlign: 'center' }}>
              <img srcSet={item.isNew ? URL.createObjectURL(item[0]) : process.env.REACT_APP_AWS_PUBLIC_URL + item.imagePath} src={item.imagePath} alt={item.title} loading="lazy" />
            </Box>
          )}
        </Box>
        {isNeedTitle && (
          <Box mt={2}>
            <FormTitle title="件名" />
            <TextField
              fullWidth
              name="title"
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
          </Box>
        )}

        {isNeedIntroduction && (
          <Box mt={2}>
            <FormTitle title="説明" />
            <TextArea
              name="introduction"
              value={data.introduction}
              onChange={(e) => setData({ ...data, introduction: e.target.value })}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={() => {
            if (!item && !data.fileName) {
              setErrorMsg('ファイルをアップロードして下さい');
              return;
            }
            onClick(item ? data : { ...data, id: newId, isNew: true });
          }}
        >
          設定
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

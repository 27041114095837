import React from 'react';
import {
  Box, TextField, Grid,
} from '@mui/material';
import { useSelector } from 'react-redux';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataMultiSelectList from '../../../components/atoms/DataMultiSelectList';
import { FormTitle } from '../../../components/atoms/Base';

export default function OpportunitySearchBox(props) {
  const {
    onSearch, onClear, onChange, condition, targetScreen, reSearch,
  } = props;
  const usetData = useSelector((state) => state.loginStore.userInfo);

  return (
    <SearchBox
      onSearch={onSearch}
      onClear={onClear}
      condition={condition}
      targetScreen={targetScreen}
      reSearch={reSearch}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <FormTitle title="会社/お客様名" />
          <TextField size="small" value={condition.name} fullWidth name="name" onChange={onChange} />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormTitle title="お客様電話番号" />
          <TextField autoComplete="off" value={condition.customerPhone} fullWidth name="customerPhone" onChange={onChange} />
        </Grid>
        {!usetData.shopId && (
          <Grid item xs={12} md={4}>
            <FormTitle title="店舗" />
            <DataMultiSelectList
              name="shopIds"
              target="shops"
              values={condition.shopIds}
              onChange={onChange}
            />
          </Grid>
        )}
      </Grid>
      <Box xs={12} md={12}>
        <FormTitle title="フリーワード" attention="店舗使用用備考、店舗向け備考、商品内容備考、ユーザー向け備考、店舗受注可否コメント" />
        <TextField autoComplete="off" value={condition.keyword} fullWidth name="keyword" onChange={onChange} />
      </Box>
    </SearchBox>
  );
}

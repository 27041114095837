import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Avatar, ListItemAvatar, ListItemText, Divider,
  ListItem, List, Box, ListItemButton, Paper,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { homeActions } from '../../redux/home/homeState';
import detailUrl from '../../constants/frontUrls';

export default function Todo() {
  const dbDatas = useSelector((state) => state.homeStore.todos);

  const [datas, setDatas] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(homeActions.getTodo());
  }, []);
  useEffect(() => {
    if (dbDatas && dbDatas.length > 0) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  const onClick = (_, data) => {
    const url = data.estimate ? detailUrl.ESTIMATE_DETAIL : detailUrl.OPPORTUNITY_DETAIL;
    navigate(url + data.id);
  };

  return (
    <Paper elevation={3}>
      <Box display="flex" pt={2} pl={2} style={{ marginBottom: '10px' }}>
        <InfoIcon />
        <Box sx={{ fontWeight: 'bold' }}>Todo</Box>
      </Box>
      <List>
        {datas.length <= 0 && (
          <ListItem alignItems="flex-start">Todoはありません。</ListItem>
        )}
        {datas.map((data, index) => (
          <React.Fragment key={data.id}>
            <ListItemButton onClick={(e) => onClick(e, data)}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  {!data.illustration && (<Avatar alt={data.estimate ? '見積' : '注文'} src="E" />)}
                  {data.illustration && (<Avatar alt="実例" src="J" />)}
                </ListItemAvatar>
                {!data.illustration && (
                  <ListItemText
                    primary={data.estimate ? '見積を確認してください。' : '注文を確認してください。'}
                    secondary={data.requestDetail && data.requestDetail.split('\n').map((t, i) => <Box component="span" color="text.primary" key={`${t} ${i + 1}`}>{t}</Box>)}
                  />
                )}
                {data.illustration && (
                  <ListItemText
                    primary="実例画像をアップしてください。"
                  />
                )}
              </ListItem>
            </ListItemButton>
            {(datas.length - 1) !== index && (<Divider variant="inset" component="li" sx={{ margin: 0 }} />)}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
}

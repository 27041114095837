import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../components/templates/dataTable/DataTable';
import { shopAttachActions } from '../../../../redux/systemSetting/shopAttach/shopAttachState';
import HolidayDialog from './HolidayDialog';
import { SuccessSnackbar } from '../../../../components/atoms/Base';

const HEAD_CELLS = [
  { id: 'serviceTypeName', label: 'サービスタイプ名', sortId: 'service_type_name' },
  { id: 'holidayTypeName', label: '休日タイプ名', sortId: 'holiday_type_name' },
  { id: 'holidayPeriod', label: '休日期間', sortId: 'holiday_period' },
  { id: 'remarks', label: 'メモ', sortId: 'remarks' },
];

export default function HolidayDataTable(props) {
  const { shopId } = props;
  const dispatch = useDispatch();
  const holidayDatas = useSelector((state) => state.shopAttachStore.holidayDatas);
  const isSaveSuccess = useSelector((state) => state.shopAttachStore.isSaveSuccess);
  const isDeleteSuccess = useSelector((state) => state.shopAttachStore.isDeleteSuccess);
  const [holidays, setHolidays] = React.useState({
    content: [],
    totalElements: 0,
  });
  const [searchCondition, setSearchCondition] = React.useState({
    shopId,
    records: 50,
    page: 0,
    order: 'asc',
    orderBy: '',
  });
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [holidayId, setHolidayId] = React.useState('');
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');

  const search = (sc) => {
    dispatch(shopAttachActions.getHolidayData(sc));
  };

  React.useEffect(() => {
    if (shopId) {
      search(searchCondition);
    }
  }, [shopId]);
  React.useEffect(() => {
    if (holidayDatas) {
      setHolidays(holidayDatas);
    }
  }, [holidayDatas]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      search(searchCondition);
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(shopAttachActions.resetFlgs());
    }
  }, [isSaveSuccess]);
  React.useEffect(() => {
    if (isDeleteSuccess) {
      search(searchCondition);
      setSuccessOpen(true);
      setSuccessMessage('削除しました');
      dispatch(shopAttachActions.resetFlgs());
    }
  }, [isDeleteSuccess]);

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const dialogOpenAdd = () => {
    setDialogOpen(true);
    setHolidayId('');
  };

  const dialogOpenEdit = (_, openedMenuRowId) => {
    setDialogOpen(true);
    setHolidayId(openedMenuRowId);
  };

  const onDelete = (openedMenuRowId) => {
    dispatch(shopAttachActions.deleteHolidayData({ ids: [openedMenuRowId] }));
  };

  const onClose = () => {
    setDialogOpen(false);
  };

  const onSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  return (
    <>
      <DataTable
        rows={holidays.content || []}
        procName="休業"
        headCells={HEAD_CELLS}
        totalElements={holidays.totalElements}
        rowsPerPage={searchCondition.records}
        page={searchCondition.page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
        onAddOpen={dialogOpenAdd}
        onEdit={dialogOpenEdit}
        onDelete={onDelete}
      />
      <HolidayDialog
        isOpen={isDialogOpen}
        onClose={onClose}
        openedMenuRowId={holidayId}
        shopId={shopId}
      />
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSnackbarClose}
        message={successMessage}
      />
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    datas: [],
    searchCondition: null,
    data: null,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getDatas: (state) => {
      state.datas = [];
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state) => {
      state.isSaveSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    resetFlgs: (state) => {
      state.isSaveSuccess = false;
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;

import React from 'react';
import { connect } from 'react-redux';
import {
  TextField, Button, Paper, Box, Alert,
} from '@mui/material';
import { loginUserActions } from '../../../redux/systemSetting/loginUser/loginUserState';
import {
  SuccessSnackbar, withParams, FormErrorText, PasswordInput,
} from '../../../components/atoms/Base';
import DataSelectList from '../../../components/atoms/DataSelectList';
import DataGrid from '../../../components/templates/DataGrid';
import Validation from './validation';
import LoginUserPasswordDialog from './LoginUserPasswordDialog';
import SystemInfo from '../../../components/templates/SystemInfo';
import detailUrl from '../../../constants/frontUrls';
import LogoutDialog from './LogoutDialog';

class LoginUserDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    const form = {
      id,
      lastName: '',
      firstName: '',
      email: '',
      password: '',
      passwordCheck: '',
      shopId: '',
      shopName: '',
      createdByName: '',
      createdAt: '',
      updatedByName: '',
      updatedAt: '',
    };

    this.state = {
      accountId: props.loginUser.userInfo.accountId,
      shopId: props.loginUser.userInfo.shopId,
      isSuccessOpen: false,
      form,
      tempForm: form,
      errorMessages: {
        lastName: '',
        email: '',
        password: '',
        passwordCheck: '',
      },
      isEdit: !form.id,
      isNew: !form.id,
      isMe: `${props.loginUser.userInfo.id}` === id,
      successMessage: '',
      isCheckModalOpen: false,
      isLogoutOpen: false,
    };
    // 詳細取得
    if (id) {
      props.dispatch(loginUserActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUserInfo.data !== this.props.loginUserInfo.data) {
      if (this.props.loginUserInfo.data) {
        this.setData(this.props.loginUserInfo.data);
      }
    }
    if (prevProps.loginUserInfo.isSaveSuccess
      !== this.props.loginUserInfo.isSaveSuccess) {
      if (this.props.loginUserInfo.isSaveSuccess) {
        this.setSuccess(this.props.loginUserInfo.saveId);
      }
    }
    if (prevProps.loginUserInfo.isPasswordSuccess
      !== this.props.loginUserInfo.isPasswordSuccess) {
      if (this.props.loginUserInfo.isPasswordSuccess) {
        this.passwordSuccess();
      }
    }
  }

  setData = (form) => {
    this.setState({ form, tempForm: form });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  setSuccess = (id) => {
    const { form, isMe } = this.state;
    const searchId = id || form.id;
    if (!form.id) {
      window.location.href = detailUrl.LOGIN_USER_DETAIL + searchId;
      return;
    }
    if (isMe) {
      this.setState({ isLogoutOpen: true, isEdit: false });
      return;
    }
    this.setState({ isEdit: false, isSuccessOpen: true, successMessage: '保存しました。' });
    this.props.dispatch(loginUserActions.getData({ id: searchId }));
  };

  passwordSuccess = () => {
    this.setState({
      isSuccessOpen: true, isEdit: false, successMessage: 'パスワードを変更しました。',
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { tempForm, errorMessages } = this.state;
    const changeTempForm = {
      ...tempForm,
      [name]: value,
    };
    this.setState({
      tempForm: changeTempForm,
      errorMessages: { ...errorMessages, [name]: Validation.formValidate(name, value) },
    });
  };

  onChangePassword = (event) => {
    const { name, value } = event.target;
    const { tempForm, errorMessages } = this.state;

    this.setState({
      tempForm: {
        ...tempForm,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, value),
        passwordCheck: Validation.formValidate('passwordCheck', value, tempForm.passwordCheck),
      },
    });
  };

  onChangePasswordCheck = (event) => {
    const { name, value } = event.target;
    const { tempForm, errorMessages } = this.state;

    this.setState({
      tempForm: {
        ...tempForm,
        [name]: value,
      },
      errorMessages: {
        ...errorMessages,
        [name]: Validation.formValidate(name, tempForm.password, value),
      },
    });
  };

  onEdit = () => {
    const { form } = this.state;
    const tempErrorMessages = {
      customerName: '',
      content: '',
      complianceDeadlineDate: '',
    };
    this.setState({ isEdit: true, errorMessages: tempErrorMessages, tempForm: form });
    this.props.dispatch(loginUserActions.getData({ id: form.id }));
  };

  onEditCancel = () => {
    this.setState({ isEdit: false });
  };

  onSave = () => {
    const { tempForm, errorMessages } = this.state;
    if (this.beforeSaveCheck(tempForm, errorMessages)) {
      return;
    }
    this.props.dispatch(loginUserActions.saveData(tempForm));
  };

  onClickModalOpen = () => {
    this.setState({ isCheckModalOpen: true });
  };

  onClickModalClose = () => {
    this.setState({ isCheckModalOpen: false });
  };

  beforeSaveCheck = (form, errorMessages) => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      if (key === 'passwordCheck') return;
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (!form.id) {
      const msg = Validation.formValidate('passwordCheck', form.password, form.passwordCheck);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        passwordCheck: msg,
      };

      this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    }

    this.setState({ errorMessages: tempMessage, isSuccessOpen: false });
    return isError;
  };

  render() {
    const {
      accountId,
      shopId,
      errorMessages,
      successMessage,
      form,
      isSuccessOpen,
      isEdit,
      isNew,
      isMe,
      isLogoutOpen,
      isCheckModalOpen,
      tempForm,
    } = this.state;

    if (isLogoutOpen) {
      return (
        <LogoutDialog isOpen={isLogoutOpen} />
      );
    }

    return (
      <>
        <Box
          style={{
            minHeight: '48px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
          }}
        >
          {(!isEdit && !isNew) && (
            <>
              {form.id && (
                <Button color="primary" variant="contained" size="small" onClick={this.onClickModalOpen} style={{ marginRight: '10px' }}>パスワード変更</Button>
              )}
              <Button color="primary" variant="contained" size="small" onClick={this.onEdit}>編集</Button>
            </>
          )}
        </Box>
        {(isEdit && isMe) && (
          <Box mb={1}>
            <Alert severity="warning">ログイン中のユーザーの為、情報が変更された場合、保存後ログアウトします。</Alert>
          </Box>
        )}
        <Paper elevation={3} style={{ padding: '10px', marginBottom: 2 }}>
          {!shopId && (
            <DataGrid title="店舗" isEdit={isEdit} value={form.shopName}>
              <DataSelectList
                name="shopId"
                target="shops"
                parameter={accountId ? { accountId } : null}
                value={tempForm.shopId}
                onChange={this.onChange}
              />
            </DataGrid>
          )}
          <DataGrid title="姓" isEdit={isEdit} value={form.lastName} isRequired>
            <TextField inputProps={{ maxLength: 255 }} value={tempForm.lastName} fullWidth name="lastName" onChange={this.onChange} error={!!errorMessages.lastName} />
            <FormErrorText>{errorMessages.lastName}</FormErrorText>
          </DataGrid>
          <DataGrid title="名" isEdit={isEdit} value={form.firstName}>
            <TextField inputProps={{ maxLength: 255 }} value={tempForm.firstName} fullWidth name="firstName" onChange={this.onChange} />
          </DataGrid>
          <DataGrid title="メールアドレス" isEdit={isEdit} value={form.email} isRequired>
            <TextField inputProps={{ maxLength: 255 }} value={tempForm.email} fullWidth name="email" onChange={this.onChange} error={!!errorMessages.email} />
            <FormErrorText>{errorMessages.email}</FormErrorText>
          </DataGrid>
          {(isEdit && isNew) && (
            <>
              <DataGrid title="パスワード" isRequired isEdit={isEdit} value={form.password}>
                <PasswordInput error={!!errorMessages.password} value={tempForm.password} name="password" onChange={this.onChangePassword} />
                <FormErrorText>{errorMessages.password}</FormErrorText>
              </DataGrid>
              <DataGrid title="確認パスワード" isRequired isEdit={isEdit} value={form.passwordCheck}>
                <PasswordInput error={!!errorMessages.passwordCheck} value={tempForm.passwordCheck} name="passwordCheck" onChange={this.onChangePasswordCheck} />
                <FormErrorText>{errorMessages.passwordCheck}</FormErrorText>
              </DataGrid>
            </>
          )}
          {!isEdit && !isNew && (
            <SystemInfo data={form} />
          )}
          {isEdit && (
            <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
              {!isNew && (
                <Button color="inherit" variant="contained" size="small" onClick={this.onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
              )}
              <Button color="primary" variant="contained" size="small" onClick={this.onSave}>保存</Button>
            </Box>
          )}
        </Paper>
        <LoginUserPasswordDialog
          isOpen={isCheckModalOpen}
          onClickModalClose={this.onClickModalClose}
          id={form.id}
        />
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserInfo: state.loginUserStore,
  loginUser: state.loginStore,
  selectInfo: state.selectStore,
});

export default withParams(connect(mapStateToProps)(LoginUserDetail));

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListItemText, Divider, Paper, Box,
  ListItem, List, ListItemIcon, Link,
} from '@mui/material';
import {
  LocalShipping as LocalShippingIcon, Nightlife as NightlifeIcon,
} from '@mui/icons-material';
import { shopAttachActions } from '../../../../redux/systemSetting/shopAttach/shopAttachState';

export default function PlanDataTable(props) {
  const { shopId } = props;
  const dispatch = useDispatch();
  const dbPlans = useSelector((state) => state.shopAttachStore.plans);
  const [datas, setDatas] = React.useState([]);
  const search = () => {
    dispatch(shopAttachActions.getPlanData({ shopId }));
  };

  React.useEffect(() => {
    if (shopId) {
      search(shopId);
    }
  }, [shopId]);
  React.useEffect(() => {
    if (dbPlans) {
      setDatas(dbPlans);
    }
  }, [dbPlans]);

  return (
    <Paper elevation={3}>
      <Box display="flex" p={2}>
        <Box sx={{ flex: 1 }} />
        <Box display="flex" justifyContent="center" mr={2}>
          <LocalShippingIcon sx={{ color: '#757575' }} />
          オードブル
        </Box>
        <Box display="flex" justifyContent="center">
          <NightlifeIcon sx={{ color: '#757575' }} />
          ケータリング
        </Box>
      </Box>
      <List>
        {datas.map((data, index) => (
          <React.Fragment key={data.id}>
            <ListItem>
              <ListItemIcon>
                {data.serviceTypeCode === '1' ? (<LocalShippingIcon />) : (<NightlifeIcon />)}
              </ListItemIcon>
              <ListItemText
                primary={(<Link target="_blank" rel="noopener noreferrer" href={`https://catering-selection.jp/shop/detail/${data.shopCode}/${data.planCode}`}>{data.name}</Link>)}
                secondary={`${data.price}円 ${data.itemNumber}品`}
              />
            </ListItem>
            {(datas.length - 1) !== index && (<Divider variant="inset" component="li" />)}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
}

/**
 * カンマ区切りでStringを配列に変換して返します
 * @param {*} targetString 対象文字
 * @returns array[]
 */
export function convertStringToList(targetString) {
  return targetString.split(',')
    .map((item) => item.replace(/\s+/g, ''))
    .filter((item) => item !== '');
}

/**
 * オブジェクト配列をkeyNameの値ごとの配列に分別してオブジェクトを返却
 * @param {*} detailArray
 * @param {*} keyName
 */
export function groupBy(detailArray, keyName) {
  return detailArray.reduce((acc, detail) => {
    const key = detail[keyName];
    if (!acc[key]) {
      return { ...acc, [key]: [detail] };
    }
    return { ...acc, [key]: [...acc[key], detail] };
  }, {});
}

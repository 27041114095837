import { createSlice } from '@reduxjs/toolkit';

export const estimateSlice = createSlice({
  name: 'estimate',
  initialState: {
    datas: [],
  },
  reducers: {
    getDatas: (state) => {
      state.datas = [];
    },
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: (state) => {
      state.data = null;
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const estimateActions = estimateSlice.actions;

export default estimateSlice.reducer;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, List, ListItem, Chip, Paper, Divider, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Info as InfoIcon } from '@mui/icons-material';
import { homeActions } from '../../redux/home/homeState';
import NoticeDetailDialog from './NoticeDetailDialog';

const CustomButton = styled(Button)(() => ({
  textAlign: 'left',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'rgba(30, 144, 255, 0)',
  },
}));

export default function Notice() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(false);
  const [datas, setDatas] = React.useState([]);
  const dbDatas = useSelector((state) => state.homeStore.notices);

  React.useEffect(() => {
    dispatch(homeActions.getNotice());
  }, []);

  React.useEffect(() => {
    if (dbDatas && dbDatas.length > 0) {
      setDatas(dbDatas);
    }
  }, [dbDatas]);

  return (
    <Box>
      <Paper elevation={3}>
        <Box display="flex" pt={2} pl={2} style={{ marginBottom: '10px' }}>
          <InfoIcon />
          <Box sx={{ fontWeight: 'bold' }}>お知らせ</Box>
        </Box>
        <List>
          {datas.length === 0 && (
            <ListItem>お知らせはありません</ListItem>
          )}
          {datas.map((data, index) => (
            <div key={data.id}>
              <ListItem sx={{ display: { xs: 'block', md: 'flex' } }}>
                <Box sx={{ minWidth: '121px' }}>{data.startDate}</Box>
                <Box mx={{ xs: 0, md: 2 }} my={{ xs: 1, md: 0 }}>
                  <Chip label={data.category} variant="outlined" />
                </Box>
                <CustomButton
                  onClick={() => { setSelectedId(data.id); setOpen(true); }}
                >
                  {data.title}
                </CustomButton>
              </ListItem>
              {(datas.length !== 1 && datas.length - 1 !== index) && (
                <Divider />
              )}
            </div>
          ))}
        </List>
      </Paper>
      <NoticeDetailDialog isOpen={open} id={selectedId} onClose={() => setOpen(false)} />
    </Box>
  );
}

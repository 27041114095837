import React from 'react';
import { connect } from 'react-redux';
import {
  Box, Table, Button, IconButton, Paper,
} from '@mui/material';
import {
  NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon,
  LocalShipping as LocalShippingIcon, Nightlife as NightlifeIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import CalendarDetailList from './calendar/CalendarDetailList';
import { opportunityListActions } from '../../../redux/opportunity/list/opportunityListState';
import { FormTitle } from '../../../components/atoms/Base';
import DataMultiSelectList from '../../../components/atoms/DataMultiSelectList';
import getCalnedarMonthly from './calendar/calendar-proc';

const CustomButton = styled(Button)(() => ({
  textAlign: 'left',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const HeaderTd = styled('th')(({ rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  verticalAlign: 'top',
  ...(rested && {
    color: 'red',
  }),
}));

const DataTd = styled('td')(({ disabled, rested }) => ({
  border: 'solid 1px #DDD',
  width: '14.2%',
  height: '80px',
  verticalAlign: 'top',
  ...(disabled && {
    background: '#e6e6e6',
    color: '#999999',
  }),
  ...(rested && {
    color: 'red',
  }),
  ...(!disabled && {
    '&:hover': {
      background: '#f1f3f4',
    },
  }),
}));

export const DEFAULT_SEARCH_CONDITION = {
  name: '',
  customerPhone: '',
  opportunityNumber: '',
  heldDateStart: '',
  statuses: [],
  shopIds: [],
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
  targetMonth: '',
};

class CalendarList extends React.Component {
  constructor(props) {
    super(props);

    const searchCondition = {
      ...DEFAULT_SEARCH_CONDITION,
      targetMonth: format(new Date(), 'yyyy/MM/dd'),
    };

    props.dispatch(opportunityListActions.getCalendars(searchCondition));
    const calendars = getCalnedarMonthly(searchCondition.targetMonth);
    this.state = {
      detailOpen: false,
      datas: [],
      calendars,
      detailCondition: {
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
        heldDateStart: '',
        heldDateEnd: '',
      },
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.calendarDatas !== this.props.opportunityInfo.calendarDatas) {
      if (this.props.opportunityInfo.calendarDatas) {
        this.setDatas(this.props.opportunityInfo.calendarDatas);
      }
    }
  }

  setDatas(datas) {
    this.setState({ datas });
  }

  onChange = (event) => {
    const { value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      shopIds: value,
    };
    this.props.dispatch(opportunityListActions.setSearchCondition(searchCondition));
    this.setState({ searchCondition: tempCondition });
    this.props.dispatch(opportunityListActions.getCalendars(tempCondition));
  };

  onDetailOpen = (_, heldDate, serviceTypeCode) => {
    const detailCondition = {
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
      heldDate,
      serviceTypeCode,
    };
    this.setState({ detailCondition, detailOpen: true });
    this.props.dispatch(opportunityListActions.getDatas(detailCondition));
  };

  onDetailClose = () => {
    this.setState({ detailOpen: false });
  };

  onBeforeMonth = () => {
    const { targetMonth } = this.state.searchCondition;
    const curDate = new Date(targetMonth);
    curDate.setMonth(curDate.getMonth() - 1);
    const newDate = format(curDate, 'yyyy/MM/dd');
    this.onCalendarResearch(newDate);
  };

  onNextMonth = () => {
    const { targetMonth } = this.state.searchCondition;
    const curDate = new Date(targetMonth);
    curDate.setMonth(curDate.getMonth() + 1);
    const newDate = format(curDate, 'yyyy/MM/dd');
    this.onCalendarResearch(newDate);
  };

  onResearch = (detailCondition) => {
    this.props.dispatch(opportunityListActions.getDatas(detailCondition));
    this.setState({ detailCondition });
  };

  onCalendarResearch = (targetMonth) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      targetMonth,
    };
    this.calcMonth(tempCondition);
    this.props.dispatch(opportunityListActions.getCalendars(tempCondition));
  };

  calcMonth = (searchCondition) => {
    if (!searchCondition.targetMonth) {
      return;
    }
    const calendars = getCalnedarMonthly(searchCondition.targetMonth);
    this.setState({ calendars, searchCondition });
  };

  render() {
    const {
      searchCondition, detailOpen, calendars, datas, detailCondition,
    } = this.state;
    const { shopId } = this.props.loginUser.userInfo;
    return (
      <Box>
        {!shopId && (
          <Paper elevation={0}>
            <Box px={2} py={2} mb={2}>
              <FormTitle title="店舗" />
              <DataMultiSelectList
                name="shopIds"
                target="shops"
                values={searchCondition.shopIds}
                onChange={this.onChange}
              />
            </Box>
          </Paper>
        )}
        <Paper elevation={0} style={{ marginBottom: '10px' }}>
          <Box style={detailOpen ? { paddingLeft: '0px', marginLeft: '0px', width: '50%' } : null}>
            <Box mb={1} style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton style={{ marginRight: '5px' }} onClick={this.onBeforeMonth} size="medium">
                <NavigateBeforeIcon />
              </IconButton>
              <Box
                style={{
                  fontWeight: 'bold',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {searchCondition.targetMonth.slice(0, -3)}
              </Box>
              <IconButton style={{ marginLeft: '5px' }} onClick={this.onNextMonth} size="medium">
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Box display="flex" mr={2}>
              <Box sx={{ flex: 1 }} />
              <Box display="flex" justifyContent="center" mr={2}>
                <LocalShippingIcon sx={{ color: '#1564b2' }} />
                オードブル
              </Box>
              <Box display="flex" justifyContent="center">
                <NightlifeIcon sx={{ color: '#1564b2' }} />
                ケータリング
              </Box>
            </Box>
            <Table
              width="100%"
              sx={{
                border: 'solid 1px #DDD',
                borderCollapse: 'collapse',
                textAlign: 'center',
                tableLayout: 'fixed',
              }}
            >
              <thead>
                <tr>
                  {['日', '月', '火', '水', '木', '金', '土'].map((week) => (
                    <HeaderTd key={week} rested={week === '日' || week === '土'}>{week}</HeaderTd>
                  ))}
                </tr>
              </thead>
              <tbody>
                {calendars.map((weekRow, index) => (
                  <tr key={`${weekRow[index].date}month`}>
                    {weekRow.map((item) => {
                      const dayDatas = datas.filter(
                        (data) => data.targetDate === item.formatDate,
                      );
                      return (
                        <DataTd
                          key={item.day}
                          disabled={!item.isSomeMonth}
                        >
                          <Box>
                            <div>{item.day}</div>
                            {dayDatas.map((data) => (
                              <Box key={data.targetDate + data.serviceTypeName} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Box display="flex">
                                  <CustomButton sx={{ padding: 0 }} variant="text" onClick={(e) => this.onDetailOpen(e, item.formatDate, data.serviceTypeCode)}>
                                    {data.serviceTypeCode === '1' ? <LocalShippingIcon /> : <NightlifeIcon />}
                                    {`${data.orderCount}件`}
                                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>{`${data.totalPrice.toLocaleString()}円`}</Box>
                                  </CustomButton>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </DataTd>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Box>
          <CalendarDetailList
            open={detailOpen}
            onClose={this.onDetailClose}
            onSearch={this.onResearch}
            searchCondition={detailCondition}
          />
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(CalendarList);

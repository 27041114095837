import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { opportunityFileActions } from './opportunityFileState';
import {
  getDatas, uploadFiles, getDataById, deleteDatas, updateData,
} from '../../../services/base.service';

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.FILE.FILES_ID, payload);
    yield put(opportunityFileActions.getDataSuccess(result));
  } catch (_) { }
}

function* insertData({ payload }) {
  try {
    const formData = new FormData();

    const requestData = {};
    Object.entries(payload).forEach(([key, value]) => {
      if (key !== 'file') {
        requestData[key] = value;
      }
    });
    formData.append('request', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));

    if (payload.file) {
      formData.append('file', payload.file);
    }

    const result = yield call(uploadFiles, REST_API.OPPORTUNITY.FILE.FILES, formData);
    yield put(opportunityFileActions.insertDataSuccess(result));
  } catch (_) { }
}

function* getFileDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.FILE.FILES, payload);
    yield put(opportunityFileActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getUrl({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.FILE.FILE, payload);
    yield put(opportunityFileActions.getUrlSuccess(result));
  } catch (e) {
    console.log(e);
  }
}

function* deleteFiles({ payload }) {
  try {
    const result = yield call(deleteDatas, REST_API.OPPORTUNITY.FILE.FILES, payload);
    yield put(opportunityFileActions.deleteDataSuccess(result));
  } catch (_) { }
}

function* update({ payload }) {
  try {
    yield call(updateData, REST_API.OPPORTUNITY.FILE.FILES_ID, payload);
    yield put(opportunityFileActions.updateDataSuccess());
  } catch (_) { }
}

function* opportunityFileSaga() {
  yield takeLatest('opportunityFile/getData', getData);
  yield takeLatest('opportunityFile/insertData', insertData);
  yield takeLatest('opportunityFile/getDatas', getFileDatas);
  yield takeLatest('opportunityFile/getUrl', getUrl);
  yield takeLatest('opportunityFile/deleteData', deleteFiles);
  yield takeLatest('opportunityFile/updateData', update);
}

export default opportunityFileSaga;

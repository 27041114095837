/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Paper, FormControlLabel, Checkbox, Grid,
} from '@mui/material';
import { shopActions } from '../../../redux/systemSetting/shop/shopState';
import DataGrid from '../../../components/templates/DataGrid';
import { FormTitle, SuccessSnackbar } from '../../../components/atoms/Base';

const DEFAULT_FORM = {
  id: '',
  name: '',
  receptionStartTime: '',
  receptionEndTime: '',
  regularHolidays: [],
};

const weekCount = [
  { count: 1, label: '第一週' },
  { count: 2, label: '第二週' },
  { count: 3, label: '第三週' },
  { count: 4, label: '第四週' },
  { count: 5, label: '第五週' },
];
const dayOfWeeks = [
  { id: 1, label: '月' },
  { id: 2, label: '火' },
  { id: 3, label: '水' },
  { id: 4, label: '木' },
  { id: 5, label: '金' },
  { id: 6, label: '土' },
  { id: 0, label: '日' },
  { id: 7, label: '祝' },
];

export default function ShopDetailInfoCard(props) {
  const { shopId } = props;
  const dispatch = useDispatch();
  const dbShop = useSelector((state) => state.shopStore.data);
  const isSaveSuccess = useSelector((state) => state.shopStore.isSaveSuccess);
  const [isSuccessOpen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isEdit, setEdit] = React.useState(false);
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const [shopData, setShopData] = React.useState({});

  const search = () => {
    dispatch(shopActions.getData({ id: shopId }));
  };

  React.useEffect(() => {
    if (dbShop) {
      setForm({
        ...dbShop.data,
        regularHolidays: dbShop.regularHolidays,
      });
      setShopData({
        ...dbShop.data,
        regularHolidays: dbShop.regularHolidays,
      });
    }
  }, [dbShop]);
  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
      setSuccessOpen(true);
      setSuccessMessage('保存しました');
      dispatch(shopActions.resetFlgs());
    }
  }, [isSaveSuccess]);

  const onEdit = () => {
    search();
    setEdit(true);
  };

  const onClose = () => {
    setEdit(false);
    setForm(shopData);
  };

  const onSuccessSnackbarClose = () => {
    setSuccessOpen(false);
    setSuccessMessage('');
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onClick = (event, count, dayOfWeekId) => {
    const { id } = shopData;
    const baseRegularHolidays = shopData.regularHolidays;
    const { regularHolidays } = form;
    // dbに登録済の休日
    const baseIndex = baseRegularHolidays.findIndex((holiday) => holiday.weekCount === count
      && holiday.dayOfWeek === dayOfWeekId);
    // 画面で操作してるリスト
    const index = regularHolidays.findIndex((holiday) => holiday.weekCount === count
      && holiday.dayOfWeek === dayOfWeekId);
    const tempList = [...regularHolidays];
    let tempScreen = {};

    if (index < 0) {
      tempScreen = baseIndex < 0
        ? { shopId: id, weekCount: count, dayOfWeek: dayOfWeekId }
        : baseRegularHolidays[baseIndex];
      tempList.push(tempScreen);
    } else {
      tempList.splice(index, 1);
    }
    const tempForm = { ...form, regularHolidays: tempList };
    setForm(tempForm);
  };

  const onSave = () => {
    dispatch(shopActions.updateData({
      id: form.id,
      receptionStartTime: form.receptionStartTime,
      receptionEndTime: form.receptionEndTime,
      regHolidayList: form.regularHolidays,
      updatedAt: form.updatedAt,
    }));
    setEdit(false);
    onClose();
  };

  return (
    <>
      <Box
        mr={1}
        style={{
          minHeight: '48px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
        }}
      >
        {!isEdit && (
          <Button variant="contained" size="small" onClick={onEdit}>編集</Button>
        )}
      </Box>
      <Paper elevation={3} style={{ padding: '10px' }}>
        <DataGrid title="店舗名" value={shopData.name} />
        <DataGrid title="営業開始時間" value={shopData.receptionStartTime} />
        <DataGrid title="営業終了時間" value={shopData.receptionEndTime} />
        <Grid container spacing={1} mb={2} style={{ wordBreak: 'break-all' }}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormTitle title="定休日" />
          </Grid>
          <Grid item xs={12} md={8}>
            {!isEdit && weekCount.map((week) => (
              <Box key={week.label} mb={1}>
                <Box>
                  {week.label}
                  &nbsp;&nbsp;
                  {dayOfWeeks.map((dayOfWeek) => {
                    let cmp;
                    const isChecked = form && form.regularHolidays.some(
                      (holiday) => (holiday.weekCount === week.count
                        && holiday.dayOfWeek === dayOfWeek.id),
                    );
                    if (isChecked) {
                      cmp = (dayOfWeek.label);
                    }
                    return (cmp);
                  })}
                </Box>
              </Box>
            ))}
            {isEdit && weekCount.map((week) => (
              <Box mb={1}>
                <Box>
                  {week.label}
                </Box>
                <Box>
                  {dayOfWeeks.map((dayOfWeek) => {
                    const isChecked = form && form.regularHolidays.some(
                      (holiday) => (holiday.weekCount === week.count
                        && holiday.dayOfWeek === dayOfWeek.id),
                    );
                    return (
                      <FormControlLabel
                        control={<Checkbox checked={isChecked} onChange={(event) => onClick(event, week.count, dayOfWeek.id)} name="screenName" />}
                        label={dayOfWeek.label}
                      />
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Grid>
        </Grid>
        {isEdit && (
          <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
            <Button color="inherit" variant="contained" size="small" onClick={onClose} style={{ marginRight: '10px' }}>キャンセル</Button>
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
      </Paper>
      <SuccessSnackbar
        open={isSuccessOpen}
        onClose={onSuccessSnackbarClose}
        message={successMessage}
      />
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

export const illustrationSlice = createSlice({
  name: 'illustration',
  initialState: {
    searchCondition: null,
    data: null,
  },
  reducers: {
    setSearchCondition: (state, action) => {
      state.searchCondition = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
      state.saveId = null;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    compImgUpload: (state) => {
      state.isCompImgUploadSuccess = false;
    },
    compImgUploadSuccess: (state, action) => {
      state.isCompImgUploadSuccess = true;
      state.saveId = action.payload;
    },
    resetFlgs: (state) => {
      state.isSaveSuccess = false;
      state.isCompImgUploadSuccess = false;
    },
  },
});

export const illustrationActions = illustrationSlice.actions;

export default illustrationSlice.reducer;

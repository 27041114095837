import React, { useState, useEffect } from 'react';
import {
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { opportunityFileActions } from '../../../redux/opportunity/opportunityFile/opportunityFileState';
import { SuccessSnackbar } from '../../../components/atoms/Base';
import FileUploadDialog from './FileUploadDialog';
import DataTable from '../../../components/templates/dataTable/DataTable';
import detailUrl from '../../../constants/frontUrls';

const headCells = [
  { id: 'fileName', label: '件名', sortId: 'file_name' },
  { id: 'createdAt', label: '作成日', sortId: 'created_at' },
  { id: 'createdBy', label: '作成者', sortId: 'created_by' },
];

export default function FileDataTable(props) {
  const [data, setData] = useState({ content: [], totalElements: 0 });
  const [openedMenuRowId, setOpenedMenuRowId] = useState(null);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [searchCondition, setSearchCondition] = useState({
    page: 0, records: 50, order: 'desc', orderBy: 'created_at',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();
  const fileDatas = useSelector((state) => state.opportunityFileStore.datas);
  const isInsertSuccess = useSelector((state) => state.opportunityFileStore.isInsertSuccess);
  const isUpdateSuccess = useSelector((state) => state.opportunityFileStore.isUpdateSuccess);
  const isDeleteSuccess = useSelector((state) => state.opportunityFileStore.isDeleteSuccess);

  const {
    estimateId,
    opportunityId,
    opportunityNum,
  } = props;

  const search = (condition) => {
    dispatch(opportunityFileActions.getDatas({ ...condition, estimateId: estimateId || '', opportunityId }));
  };

  useEffect(() => {
    if (estimateId || opportunityId) {
      dispatch(opportunityFileActions.getDatas({ ...searchCondition, estimateId: estimateId || '', opportunityId }));
    }
  }, [estimateId, opportunityId]);

  useEffect(() => {
    if (isDeleteSuccess) {
      setSuccessMessage('削除しました');
      setOpenSuccess(true);
      search(searchCondition);
      dispatch(opportunityFileActions.resetFlg());
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isInsertSuccess || isUpdateSuccess) {
      setSuccessMessage('保存しました');
      setOpenSuccess(true);
      search(searchCondition);
      dispatch(opportunityFileActions.resetFlg());
      setIsOpenDialog(false);
    }
  }, [isInsertSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (opportunityId && fileDatas) {
      setData(fileDatas);
    }
  }, [fileDatas]);

  const onDelete = (id) => {
    const ids = [id];
    dispatch(opportunityFileActions.deleteData({ ids }));
    setOpenedMenuRowId(null);
  };

  const onChangePage = (_, newPage) => {
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy: orderBy || 'created_at',
      order: (orderBy || searchCondition.orderBy === 'created_at') ? order : 'desc',
    };
    setSearchCondition(tempCondition);
    search(tempCondition);
  };

  const onUpload = () => {
    setOpenedMenuRowId(null);
    setIsOpenDialog(true);
  };

  const onEdit = (_, id) => {
    setOpenedMenuRowId(id);
    setIsOpenDialog(true);
  };

  const onCloseDialog = () => {
    setOpenedMenuRowId(null);
    setIsOpenDialog(false);
  };

  const getFile = (row) => {
    const pageUrl = detailUrl.FILE + row.id;
    window.open(pageUrl, '_blank');
  };

  return (
    <div>
      <DataTable
        rows={data.content || []}
        headCells={headCells}
        onAddOpen={onUpload}
        procName="ファイル"
        page={searchCondition.page}
        rowsPerPage={searchCondition.records}
        totalElements={data.totalElements}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onEdit={onEdit}
        onDelete={onDelete}
        onOpen={getFile}
        handleRequestSort={handleRequestSort}
        order={searchCondition.order}
        orderBy={searchCondition.orderBy}
      />
      <FileUploadDialog
        openedMenuRowId={openedMenuRowId}
        isOpen={isOpenDialog}
        onClose={onCloseDialog}
        opportunityNum={opportunityNum}
        opportunityId={opportunityId}
        estimateId={estimateId}
      />
      <SuccessSnackbar
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        message={successMessage}
      />
    </div>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Button, Alert, Snackbar,
} from '@mui/material';
import { opportunityDetailActions } from '../../../redux/opportunity/opportunity/opportunityDetailState';
import { illustrationActions } from '../../../redux/systemSetting/illustration/illustrationState';
import {
  withParams, SuccessSnackbar, TabPanel,
} from '../../../components/atoms/Base';
import FileDataTable from '../fileDataTable/FileDataTable';
import OpportunityDetailInfo from './OpportunityDetailInfo';
import OrderList from './OrderList';
import IllustrationTable from './IllustrationTable';
import EstimateList from './EstimateList';
import detailUrl from '../../../constants/frontUrls';
import AnswerDialog from './AnswerDialog';
import Tabs from '../../../components/templates/Tabs';
import DeliveryFileTable from './DeliveryFileList';

class OpportunityDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    const form = {
      id,
      name: '', // お客様名
      orderId: '', // 注文ID
      opportunityNumber: '', // 案件番号
      sales: '', // 営業(id)
      salesName: '', // 営業名
      orderReceptionDate: '', // 注文受付日
      complianceDeadlineDate: '', // 対応期限日時
      totalAmount: '', // 総合計金額
      eventDate: '', // 開催日
      openTime: '', // 開催時間
      route: '', // 受注経路(id)
      routeName: '', // 受注経路名
      paymentMethod: '', // 支払方法
      paymentMethodName: '', // 支払方法名
      receipt: '', // 領収書
      paymentDay: '', // 入金日
      deliveryAddress: '', // 納品先住所
      paymentLimitDay: '', // 支払期限
      receiptAddress: '', // 領収書宛名
      note: '', // 但書注意
      inputTargetCheck: '', // 入力対象チェック
      isSendConfirmation: false, // 確定メール送付
      isClaimed: false, // 請求済
      isYuizenArrangement: false, // 結膳手配有無
      account: '', // 提携企業(id)
      accountName: '', // 提携企業
      accountNumber: 0, // 提携企業候補数
      companyName: '', // 所属先会社
      branchOfficeName: '', // 支店営業所
      phone: '', // 電話
      mail: '', // メール
      poNumber: '', // PO番号
      numberOfAgency: '', // 代行案件数
      numberOfYuzenShops: '', // 結膳店舗数
      orderStatusDetails: '', // 注文状態詳細
      notesForInternal: '', // 社内用備考
      notesForShop: '', // 店舗向け備考
      notesForProduct: '', // 商品内容備考
      notesForUser: '', // ユーザー向け備考
      requestDetail: '', // 店舗への依頼内容
    };

    let successMessage = '';
    let isSuccessOpen = false;
    if (props.opportunityInfo.isCreateSuccess) {
      props.dispatch(opportunityDetailActions.resetFlg());
      successMessage = '作成しました。';
      isSuccessOpen = true;
    }
    this.state = {
      id,
      isSuccessOpen,
      successMessage,
      data: {
        ...form,
      },
      isAnswerDialogOpen: false,
      tabValue: 0,
    };
    if (id) {
      props.dispatch(opportunityDetailActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.data !== this.props.opportunityInfo.data) {
      if (this.props.opportunityInfo.data) {
        this.setData(this.props.opportunityInfo.data);
      }
    }
    if (prevProps.opportunityInfo.isSaveSuccess
      !== this.props.opportunityInfo.isSaveSuccess) {
      if (this.props.opportunityInfo.isSaveSuccess) {
        this.saveSuccess(this.props.opportunityInfo.saveId);
      }
    }
    if (prevProps.illustrationInfo.isSaveSuccess
      !== this.props.illustrationInfo.isSaveSuccess) {
      if (this.props.illustrationInfo.isSaveSuccess) {
        this.saveIllustSuccess(this.props.illustrationInfo.saveId);
      }
    }
    if (prevProps.illustrationInfo.isCompImgUploadSuccess
      !== this.props.illustrationInfo.isCompImgUploadSuccess) {
      if (this.props.illustrationInfo.isCompImgUploadSuccess) {
        this.saveIllustSuccess(this.props.illustrationInfo.saveId);
      }
    }
  }

  saveIllustSuccess = () => {
    this.props.dispatch(illustrationActions.resetFlgs());
    this.setState({
      isSuccessOpen: true,
      successMessage: '保存しました。',
    });
  };

  saveSuccess = (saveId) => {
    const { id } = this.state;
    this.props.dispatch(opportunityDetailActions.resetFlg());
    if (!id) {
      window.location.href = detailUrl.OPPORTUNITY_DETAIL + saveId;
      return;
    }
    this.setState({
      id: saveId,
      isSuccessOpen: true,
      successMessage: '保存しました。',
      tabValue: 0,
    });
    this.props.dispatch(opportunityDetailActions.getData({ id: saveId }));
  };

  answerSuccess = () => {
    const { id } = this.state;
    this.setState({ isAnswerDialogOpen: false, isSuccessOpen: true, successMessage: '回答しました。' });
    this.props.dispatch(opportunityDetailActions.getData({ id }));
  };

  setData = (data) => {
    this.setState({ data });
  };

  onCloseSnackbar = () => {
    this.setState({ isSuccessOpen: false });
  };

  onAnswer = () => {
    this.setState({ isAnswerDialogOpen: true });
  };

  onCloseAnswerDialog = () => {
    this.setState({ isAnswerDialogOpen: false });
  };

  render() {
    const {
      isSuccessOpen,
      successMessage,
      isDisabled,
      data,
      id,
      isAnswerDialogOpen,
      tabValue,
    } = this.state;

    let tabIndex = {
      order: -1,
      file: -1,
      illust: -1,
      estimate: -1,
    };
    const tabs = (datas) => {
      if (!datas.orderId
        || datas.opportunityStatusCode === 1
        || datas.opportunityStatusCode === 2) {
        tabIndex = {
          order: -1,
          file: 1,
          illust: -1,
          estimate: 0,
          deliveryFile: -1,
        };
        return ['見積明細', 'ファイル'];
      }
      const tab = [];
      let index = 0;
      if (datas.orderId) {
        tab.push('注文明細');
        tabIndex.order = index;
        index += 1;
      }
      tab.push('ファイル');
      tabIndex.file = index;
      index += 1;
      if (datas.existIllst) {
        tab.push('実例画像');
        tabIndex.illust = index;
        index += 1;
      }
      if (datas.estimateId) {
        tab.push('見積明細');
        tabIndex.estimate = index;
        index += 1;
      }
      tab.push('搬入経路ファイル');
      tabIndex.deliveryFile = index;
      index += 1;
      return tab;
    };

    return (
      <>
        {data.requestDetail && data.ball === '店舗' && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Box>
              <Alert
                elevation={6}
                severity="info"
              >
                {data.requestDetail && data.requestDetail.split('\n').map((t, i) => <Box key={`${t} ${i + 1}`}>{t}</Box>)}
              </Alert>
            </Box>
          </Snackbar>
        )}
        { id && (
          <Box textAlign="right" mr={2}>
            { (data.serviceTypeCode === 1 && !data.shopPropriety && data.shopPropriety !== 0) && (<Button variant="contained" size="small" onClick={this.onAnswer}>回答</Button>)}
          </Box>
        )}
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} md={id ? 5 : 12}>
            <OpportunityDetailInfo
              id={id}
              isDisabled={isDisabled}
            />
          </Grid>
          {id && (
          <Grid item xs={12} md={7}>
            <Tabs
              value={tabValue}
              onChange={(e, value) => this.setState({ tabValue: value })}
              tabs={tabs(data)}
            />
            <TabPanel value={tabValue} index={tabIndex.order}>
              <OrderList id={data.orderId} />
            </TabPanel>
            <TabPanel value={tabValue} index={tabIndex.file}>
              <FileDataTable
                isDisabled={isDisabled}
                opportunityId={data.id}
                opportunityNum={data ? data.opportunityNumber : null}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={tabIndex.illust}>
              <IllustrationTable
                opportunityId={data.id}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={tabIndex.estimate}>
              <EstimateList id={data.id} />
            </TabPanel>
            <TabPanel value={tabValue} index={tabIndex.deliveryFile}>
              <DeliveryFileTable id={data.id} />
            </TabPanel>
          </Grid>
          )}
          <AnswerDialog
            id={id}
            onSuccess={this.answerSuccess}
            isOpen={isAnswerDialogOpen}
            onClose={this.onCloseAnswerDialog}
            data={data}
          />
        </Grid>
        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onCloseSnackbar}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityDetailStore,
  illustrationInfo: state.illustrationStore,
  loginUser: state.loginStore,
  order: state.orderStore,
  estimate: state.estimateStore,
});

export default withParams(connect(mapStateToProps)(OpportunityDetail));

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField, DialogContent, DialogActions,
  Button, Box,
} from '@mui/material';
import DataGrid from '../../../components/templates/DataGrid';
import Dialog from '../../../components/atoms/Dialog';
import Dropzone from '../../../components/atoms/Dropzone';
import { opportunityFileActions } from '../../../redux/opportunity/opportunityFile/opportunityFileState';
import Validation from './validation';
import { FormErrorText, LoadingButton } from '../../../components/atoms/Base';

const DEFAULT_FORM = {
  id: '',
  fileName: '',
  publicAll: false,
  file: [],
};

const DEFAULT_ERROR_MESSAGE = {
  file: '',
  fileName: '',
};

export default function FileUploadDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    opportunityNum,
    opportunityId,
    estimateId,
  } = props;
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.opportunityFileStore.data);
  const [errorMessages, setErrorMessages] = React.useState(DEFAULT_ERROR_MESSAGE);
  const [title, setTitle] = React.useState('');
  const [form, setForm] = React.useState(DEFAULT_FORM);

  React.useEffect(() => {
    if (isOpen) {
      if (!openedMenuRowId) {
        setTitle('アップロード');
      } else {
        dispatch(opportunityFileActions.getData({ id: openedMenuRowId }));
        setTitle('件名の変更');
      }
      setForm({
        ...DEFAULT_FORM,
        opportunityNum,
        opportunityId,
        estimateId,
      });
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (fileData && openedMenuRowId) {
      setForm(fileData);
    }
  }, [fileData]);

  const onFileChange = (value) => {
    setForm({
      ...form,
      file: value[0],
    });
    const msg = Validation.formValidate('file', value[0]);
    setErrorMessages({ ...errorMessages, file: msg });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    const msg = Validation.formValidate([name], value);
    setErrorMessages({ ...errorMessages, [name]: msg });
  };

  const onSave = () => {
    let isError = false;
    let tempMessage = errorMessages;
    Object.keys(form).forEach((key) => {
      const msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });
    setErrorMessages(tempMessage);
    if (isError) return;
    const tempForm = {
      ...form,
      opportunityNum,
      opportunityId,
      estimateId: estimateId || null,
    };
    if (!form.id) {
      dispatch(opportunityFileActions.insertData(tempForm));
    } else {
      dispatch(opportunityFileActions.updateData(form));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent dividers>
        {!openedMenuRowId && (
          <Box mb={3}>
            <Dropzone multiple={false} onChange={onFileChange} />
            <FormErrorText>{errorMessages.file}</FormErrorText>
          </Box>
        )}
        <DataGrid title="件名" isEdit isRequired>
          <TextField value={form.fileName} inputProps={{ maxLength: 100 }} name="fileName" onChange={onChange} fullWidth error={!!errorMessages.fileName} />
          <FormErrorText>{errorMessages.fileName}</FormErrorText>
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setErrorMessages(DEFAULT_ERROR_MESSAGE); setForm(DEFAULT_FORM); onClose(); }} color="inherit">キャンセル</Button>
        <LoadingButton onClick={onSave}>保存</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { opportunityListActions } from '../../../redux/opportunity/list/opportunityListState';
import DataTable from '../../../components/templates/dataTable/DataTable';
import OpportunitySearchBox from './OpportunitySearchBox';

export const DEFAULT_SEARCH_CONDITION = {
  name: '',
  customerPhone: '',
  opportunityNumber: '',
  statuses: [],
  shopIds: [],
  keyword: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
  targetMonth: '',
};

class OpportunityList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'opportunityNumber', label: '案件番号', sortId: 'opportunity_number' },
      { id: 'opportunityStatusName', label: 'ステータス', sortId: 'opportunity_status_name' },
      {
        id: 'totalPrice', label: '税込合計金額', sortId: 'total_price', numeric: true,
      },
      { id: 'heldDate', label: '開催日', sortId: 'held_date' },
      { id: 'serviceTypeName', label: 'サービスタイプ名', sortId: 'service_type_name' },
      { id: 'joinNumber', label: '参加人数', sortId: 'join_number' },
      { id: 'shopRemarks', label: '店舗使用用備考', sortId: 'shop_remarks' },
    ];

    let searchCondition = props.opportunityInfo.searchCondition || DEFAULT_SEARCH_CONDITION;
    searchCondition = { ...searchCondition, statuses: this.props.statuses || [] };

    this.props.dispatch(opportunityListActions.getDatas(searchCondition));

    this.state = {
      datas: {},
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opportunityInfo.datas !== this.props.opportunityInfo.datas) {
      if (this.props.opportunityInfo.datas) {
        this.setDatas(this.props.opportunityInfo.datas);
      }
    }
    if (prevProps.statuses !== this.props.statuses) {
      this.onSearchConst(this.props.statuses);
    }
  }

  onSearchConst = (statuses) => {
    const { searchCondition } = this.state;
    const tempSearchCondition = { ...searchCondition, statuses: statuses || [] };

    this.props.dispatch(opportunityListActions.getDatas(tempSearchCondition));
    this.setState({
      searchCondition: tempSearchCondition,
    });
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(opportunityListActions.setSearchCondition(searchCondition));
    const tempSearchCondition = {
      ...searchCondition,
      targetMonth: '',
    };
    this.setState({ searchCondition: tempSearchCondition });
    this.props.dispatch(opportunityListActions.getDatas(tempSearchCondition));
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onDelete = (ids) => {
    this.props.dispatch(opportunityListActions.deleteData({ ids }));
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas, searchCondition,
    } = this.state;
    const { screenId } = this.props;

    return (
      <Box>
        <OpportunitySearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          targetScreen={`opportunity${screenId}`}
          reSearch={this.reSearch}
          condition={searchCondition}
          onChange={this.onChange}
        />
        <DataTable
          url={this.props.url}
          rows={datas.content || []}
          headCells={this.headCells}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements || 0}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  opportunityInfo: state.opportunityListStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(OpportunityList);

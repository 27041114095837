import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { REST_API } from '../../../constants/serverUrls';
import { estimateActions } from './estimateState';
import {
  getDatas, getDataById,
} from '../../../services/base.service';

function* getestimateDatas({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_DETAIL, payload);
    yield put(estimateActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.OPPORTUNITY.ESTIMATE.ESTIMATE_ID, payload);
    yield put(estimateActions.getDataSuccess(result));
  } catch (_) { }
}

function* estimateSaga() {
  yield takeLatest('estimate/getData', getData);
  yield takeLatest('estimate/getDatas', getestimateDatas);
}

export default estimateSaga;

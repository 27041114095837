import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Link, Drawer, CssBaseline, AppBar as MuiAppBar, Toolbar,
  Typography, Divider, IconButton, Button, Menu, MenuItem, useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Menu as MenuIcon, ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import { urls } from '../../constants/frontUrls';
import RouterBreadcrumbs from './RouterBreadcrumbs';
import HeaderMenuItem from './HeaderMenuItem';
import Loading from './Loading';
import AlertButton from './AlertButton';

const drawerWidth = 240;

const CenteredTypography = styled(Typography)(() => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none',
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled('main')(({ theme, open }) => ({
  flexGrow: 1,
  marginTop: 66,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.up('md')]: {
    marginLeft: `${drawerWidth}px`,
  },
}));

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  paddingTop: 10,
  paddingBottom: 5,
  paddingLeft: 15,
  paddingRight: 15,
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 16,
    marginLeft: '16px',
  },
}));

const commonAppBarStyles = (theme) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.common.white,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  ...commonAppBarStyles(theme),
}));

export default function Layout(props) {
  const isPc = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(isPc);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [closeAnchorEl, setCloseAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(isPc);
  };

  const onCloseAnchorClose = () => {
    setCloseAnchorEl(null);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const onClickLogout = () => {
    localStorage.clear();
    window.location.href = urls.COMMON.LOGIN.url;
  };

  const onClickPassword = () => {
    navigate(urls.PROFILE.PASSWORD.url);
    setProfileAnchorEl(null);
  };

  const usetData = useSelector((state) => state.loginStore.userInfo);
  const userInfo = usetData || {};

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {!isPc && (
            <Link href="/" underline="none" color="white">加盟店様マイページ</Link>
            )}
          </Typography>
          <Menu
            anchorEl={closeAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(closeAnchorEl)}
            onClose={onCloseAnchorClose}
          />
          <AlertButton />
          <div>
            <Button
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Typography variant="h6" noWrap>
                {userInfo && userInfo.name}
              </Typography>
            </Button>
            <Menu
              anchorEl={profileAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(profileAnchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={onClickPassword}>
                パスワード変更
              </MenuItem>
              <Divider />
              <MenuItem onClick={onClickLogout}>
                ログアウト
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          { !isPc && (
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
          )}
          {isPc && (
          <CenteredTypography variant="h6" noWrap component="div">
            <Link href="/" underline="none" color="white">加盟店様マイページ</Link>
          </CenteredTypography>
          )}
        </DrawerHeader>
        <Divider />

        {urls.OPPORTUNITY.filter((row) => row.isMenuDisplay).map((item) => (
          <HeaderMenuItem
            onClose={handleDrawerClose}
            url={item.url}
            menuName={item.url === '/check' && userInfo && userInfo.counts && userInfo.counts > 0 ? `要確認(${userInfo.counts})` : item.name}
            key={item.url}
            color={item.url === '/check' && userInfo && userInfo.counts && userInfo.counts > 0 ? 'error' : ''}
          />
        ))}

        <Divider />

        {urls.SYSTEM_SETTING.filter((row) => row.isMenuDisplay).map((item) => (
          <HeaderMenuItem
            onClose={handleDrawerClose}
            url={item.url}
            menuName={item.name}
            key={item.url}
          />
        ))}
      </Drawer>
      <Loading />
      <Main open={open}>
        <Box>
          <RouterBreadcrumbs />
        </Box>
        <MainContent>
          {props.children}
        </MainContent>
      </Main>
    </Box>
  );
}

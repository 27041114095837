import commonReducer from './common/common/commonState';
import conditionReducer from './common/condition/conditionState';
import loginReducer from './login/loginState';

import opportunityReducer from './opportunity/list/opportunityListState';
import opportunityFileReducer from './opportunity/opportunityFile/opportunityFileState';

import shopReducer from './systemSetting/shop/shopState';
import reviewReducer from './systemSetting/review/reviewState';
import loginUserReducer from './systemSetting/loginUser/loginUserState';
import shopAttachReducer from './systemSetting/shopAttach/shopAttachState';
import homeReducer from './home/homeState';
import estimateReducer from './opportunity/estimate/estimateState';
import opportunityDetailReducer from './opportunity/opportunity/opportunityDetailState';
import mailTemplateReducer from './systemSetting/mailTemplate/mailTemplateState';
import illustrationReducer from './systemSetting/illustration/illustrationState';

const rootReducer = {
  loginStore: loginReducer,
  commonStore: commonReducer,
  conditionStore: conditionReducer,
  homeStore: homeReducer,

  opportunityListStore: opportunityReducer,
  opportunityDetailStore: opportunityDetailReducer,
  opportunityFileStore: opportunityFileReducer,
  estimateStore: estimateReducer,

  shopStore: shopReducer,
  shopAttachStore: shopAttachReducer,
  reviewStore: reviewReducer,
  loginUserStore: loginUserReducer,
  mailTemplateStore: mailTemplateReducer,
  illustrationStore: illustrationReducer,
};

export default rootReducer;

import { all } from '@redux-saga/core/effects';

import conditionSaga from './common/condition/conditionSaga';
import loginSaga from './login/loginSaga';

import opportunityListSaga from './opportunity/list/opportunityListSaga';
import opportunityDetailSaga from './opportunity/opportunity/opportunityDetailSaga';
import opportunityFileSaga from './opportunity/opportunityFile/opportunityFileSaga';

import shopSaga from './systemSetting/shop/shopSaga';
import reviewSaga from './systemSetting/review/reviewSaga';
import loginUserSaga from './systemSetting/loginUser/loginUserSaga';
import shopAttachSaga from './systemSetting/shopAttach/shopAttachSaga';
import homeSaga from './home/homeSaga';
import estimateSaga from './opportunity/estimate/estimateSaga';
import illustrationSaga from './systemSetting/illustration/illustrationSaga';

export default function* rootSaga() {
  yield all([
    conditionSaga(),
    loginSaga(),
    homeSaga(),

    opportunityListSaga(),
    opportunityDetailSaga(),
    opportunityFileSaga(),
    estimateSaga(),
    illustrationSaga(),

    reviewSaga(),
    shopSaga(),
    shopAttachSaga(),
    loginUserSaga(),
  ]);
}

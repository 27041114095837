import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Alert, DialogContent, DialogActions, Button, Paper, Typography, Divider, Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { loginActions } from '../../redux/login/loginState';
import {
  FormTitle, FormErrorText, PasswordInput,
} from '../../components/atoms/Base';
import Validation from './validation';
import Dialog from '../../components/atoms/Dialog';
import { urls } from '../../constants/frontUrls';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    const token = window.location.pathname.split('/')[2];

    this.state = {
      token,
      password: '',
      checkPassword: '',
      passwordError: '',
      checkPasswordError: '',
      open: false,
      isAvailabilityToken: false,
      isAvailabilityUser: false,
    };

    props.dispatch(loginActions.getResetPasswordToken({ token }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginInfo.availabilityInfo !== this.props.loginInfo.availabilityInfo) {
      if (this.props.loginInfo.availabilityInfo) {
        this.setData(this.props.loginInfo.availabilityInfo);
      }
    }
    if (prevProps.loginInfo.isResetPasswordSuccess
      !== this.props.loginInfo.isResetPasswordSuccess) {
      if (this.props.loginInfo.isResetPasswordSuccess) {
        this.onSuccessOpen();
      }
    }
  }

  setData = (availabilityInfo) => {
    this.setState({
      isAvailabilityToken: availabilityInfo.availabilityToken,
      isAvailabilityUser: availabilityInfo.availabilityUser,
    });
  };

  onSuccessOpen = () => {
    this.setState({ open: true });
  };

  onPasswordChange = (event) => {
    const { checkPassword, checkPasswordError } = this.state;
    const { value } = event.target;

    let tempCheckPasswordError = checkPasswordError;
    if (checkPassword) {
      tempCheckPasswordError = Validation.formValidate('checkPassword', value, checkPassword);
    }
    this.setState({
      password: event.target.value,
      passwordError: Validation.formValidate('password', value, null),
      checkPasswordError: tempCheckPasswordError,
    });
  };

  oncheckPasswordChange = (event) => {
    const { password } = this.state;

    this.setState({
      checkPassword: event.target.value,
      checkPasswordError: Validation.formValidate('checkPassword', password, event.target.value),
    });
  };

  onSave = () => {
    const {
      token, password, checkPassword,
    } = this.state;
    const passwordError = Validation.formValidate('password', password, null);
    const checkPasswordError = Validation.formValidate('checkPassword', password, checkPassword);
    const isError = (passwordError || checkPasswordError);

    this.setState({ passwordError, checkPasswordError });

    if (isError) {
      return;
    }

    this.props.dispatch(loginActions.resetPassword({ password, token }));
  };

  // eslint-disable-next-line class-methods-use-this
  onClose = () => {
    window.location.href = urls.COMMON.LOGIN.url;
  };

  render() {
    const {
      password, passwordError,
      checkPassword, checkPasswordError, open,
      isAvailabilityToken, isAvailabilityUser,
    } = this.state;

    return (
      <Container
        component="main"
        maxWidth="md"
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={15} style={{ padding: '30px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            mb={2}
          >
            <Typography mb={2} component="h1" variant="h5">
              パスワードリセット
            </Typography>
            {(isAvailabilityToken && isAvailabilityUser) && (
              <>
                <Alert severity="info">
                  <Box>8文字以上16文字以下で半角英数字、記号(! # $ @ . _)のみが使用できます。</Box>
                </Alert>
                <Box sx={{ width: '100%' }} my={2}>
                  <FormTitle title="パスワード" />
                  <PasswordInput
                    name="password"
                    value={password}
                    error={!!passwordError}
                    onChange={this.onPasswordChange}
                  />
                  <FormErrorText>{passwordError}</FormErrorText>
                </Box>
                <Box sx={{ width: '100%' }} my={2}>
                  <FormTitle title="確認パスワード" />
                  <PasswordInput
                    name="checkPassword"
                    value={checkPassword}
                    error={!!checkPasswordError}
                    onChange={this.oncheckPasswordChange}
                  />
                  <FormErrorText>{checkPasswordError}</FormErrorText>
                </Box>
                <Box mt={2} textAlign="center">
                  <LoadingButton variant="contained" loading={this.props.common.isLoading} onClick={this.onSave} disabled={!!passwordError || !!checkPasswordError || !checkPassword || !password}>保存</LoadingButton>
                </Box>
                <Dialog
                  title="パスワード変更"
                  isOpen={open}
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogContent dividers>
                    <Box>パスワードの変更が完了しました</Box>
                    <Box>再ログインしてください</Box>
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" onClick={this.onClose}>ログイン</Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            {!isAvailabilityToken && (
              <Box>リンクの有効期限が過ぎています。</Box>
            )}
            {(isAvailabilityToken && !isAvailabilityUser) && (
              <Box>ご登録されているユーザーは削除されています。</Box>
            )}
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.cqree.jp/corporate/" target="_blank">
                Cqree INC.
              </Link>
              {' '}
              {new Date().getFullYear()}
              .
            </Typography>
          </Box>
        </Paper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.commonStore,
  loginInfo: state.loginStore,
});

export default connect(mapStateToProps)(ResetPassword);

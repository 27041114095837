import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

export default function Dropzone(props) {
  const {
    onChange,
  } = props;
  const [imagePath, setImagePath] = useState(null);
  const [fileName, setFileName] = useState('');
  const [preview, setPreview] = useState(false);
  const onDrop = (files) => {
    const file = files[0];
    const tmpFileName = file.name;
    const extension = tmpFileName.split('.').pop().toLowerCase();

    setFileName(tmpFileName);

    // 画像ファイルかどうかを判定
    if (['jpg', 'jpeg', 'png'].includes(extension)) {
      setImagePath(URL.createObjectURL(file));
      setPreview(true);
    } else {
      setImagePath(null);
      setPreview(false);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

  React.useEffect(() => {
    if (acceptedFiles.length) {
      onChange(acceptedFiles);
    }
  }, [acceptedFiles]);

  React.useEffect(() => {
    if (!fileName) {
      setPreview(false);
      setImagePath(null);
    }
  }, [fileName]);

  return (
    <>
      <Box
        m={1}
        {...getRootProps()}
        sx={{
          border: '4px dashed rgba(0, 0, 0, 0.12)',
          cursor: 'pointer',
          borderRadius: '4px',
          minHeight: '200px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <input {...getInputProps()} />
        <Box>ファイルをドラッグ＆ドロップ、またはクリックして選択</Box>
        <Box mt={2}>
          <CloudUploadIcon sx={{ fontSize: 40 }} />
        </Box>
      </Box>
      {preview && (
      <Box sx={{ overflowY: 'auto', textAlign: 'center' }}>
        <img src={imagePath} style={{ width: '100%', height: '200px' }} alt="viewer" />
        <Box style={{ marginTop: '0.5em' }}>
          {fileName}
        </Box>
      </Box>
      )}
      {!imagePath && !preview && fileName && (
      <Box style={{
        marginTop: '1.5em',
        marginBottom: '1.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Box>
          <DescriptionOutlinedIcon style={{ fontSize: 40 }} />
        </Box>
        <Box>
          {fileName}
        </Box>
      </Box>
      )}
    </>
  );
}

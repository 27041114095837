import React from 'react';
import { connect } from 'react-redux';
import {
  Container, Box, Alert, DialogContent, DialogActions, Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { loginUserActions } from '../../redux/systemSetting/loginUser/loginUserState';
import {
  FormTitle, FormErrorText, PasswordInput,
} from '../../components/atoms/Base';
import Validation from './validation';
import Dialog from '../../components/atoms/Dialog';
import { urls } from '../../constants/frontUrls';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.loginUser.userInfo;

    this.state = {
      id,
      password: '',
      checkPassword: '',
      passwordError: '',
      checkPasswordError: '',
      loginUserInfoData: {},
      open: false,
    };

    props.dispatch(loginUserActions.getData({ id }));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUserInfo.data !== this.props.loginUserInfo.data) {
      if (this.props.loginUserInfo.data) {
        this.setData(this.props.loginUserInfo.data);
      }
    }
    if (prevProps.loginUserInfo.isPasswordSuccess
      !== this.props.loginUserInfo.isPasswordSuccess) {
      if (this.props.loginUserInfo.isPasswordSuccess) {
        this.onSuccessOpen();
      }
    }
  }

  setData = (data) => {
    this.setState({ loginUserInfoData: data });
  };

  onSuccessOpen = () => {
    this.setState({ open: true });
  };

  onPasswordChange = (event) => {
    const { checkPassword, checkPasswordError } = this.state;
    const { value } = event.target;

    let tempCheckPasswordError = checkPasswordError;
    if (checkPassword) {
      tempCheckPasswordError = Validation.formValidate('checkPassword', value, checkPassword);
    }
    this.setState({
      password: event.target.value,
      passwordError: Validation.formValidate('password', value, null),
      checkPasswordError: tempCheckPasswordError,
    });
  };

  oncheckPasswordChange = (event) => {
    const { password } = this.state;

    this.setState({
      checkPassword: event.target.value,
      checkPasswordError: Validation.formValidate('checkPassword', password, event.target.value),
    });
  };

  onSave = () => {
    const {
      id, password, checkPassword, loginUserInfoData,
    } = this.state;
    const passwordError = Validation.formValidate('password', password, null);
    const checkPasswordError = Validation.formValidate('checkPassword', password, checkPassword);
    const isError = (passwordError || checkPasswordError);

    this.setState({ passwordError, checkPasswordError });

    if (isError) {
      return;
    }

    const passwordForm = {
      id, password, updatedAt: loginUserInfoData.updatedAt,
    };
    this.props.dispatch(loginUserActions.savePassword(passwordForm));
  };

  // eslint-disable-next-line class-methods-use-this
  onClose = () => {
    window.location.href = urls.COMMON.LOGIN.url;
  };

  render() {
    const {
      password, passwordError,
      checkPassword, checkPasswordError, open,
    } = this.state;

    return (
      <Container maxWidth="xl">
        <Alert severity="info">
          <Box>パスワード変更後、自動ログアウトします。</Box>
          <Box>8文字以上16文字以下で半角英数字、記号(! # $ @ . _)のみが使用できます。</Box>
        </Alert>
        <Box my={2}>
          <FormTitle title="パスワード" />
          <PasswordInput
            name="password"
            value={password}
            error={!!passwordError}
            onChange={this.onPasswordChange}
          />
          <FormErrorText>{passwordError}</FormErrorText>
        </Box>
        <Box my={2}>
          <FormTitle title="確認パスワード" />
          <PasswordInput
            name="checkPassword"
            value={checkPassword}
            error={!!checkPasswordError}
            onChange={this.oncheckPasswordChange}
          />
          <FormErrorText>{checkPasswordError}</FormErrorText>
        </Box>
        <Box mt={2} textAlign="center">
          <LoadingButton variant="contained" loading={this.props.common.isLoading} onClick={this.onSave} disabled={!!passwordError || !!checkPasswordError || !checkPassword || !password}>保存</LoadingButton>
        </Box>
        <Dialog
          title="パスワード変更"
          isOpen={open}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent dividers>
            <Box>パスワードの変更が完了しました</Box>
            <Box>再ログインしてください</Box>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.onClose}>ログアウトする</Button>
          </DialogActions>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.commonStore,
  loginUser: state.loginStore,
  loginUserInfo: state.loginUserStore,
});

export default connect(mapStateToProps)(ChangePassword);

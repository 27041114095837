import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box, Paper, Button, Chip,
} from '@mui/material';
import { TextArea } from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import { opportunityDetailActions } from '../../../redux/opportunity/opportunity/opportunityDetailState';

export default function OpportunityDetailInfo(props) {
  const {
    id, isDisabled,
  } = props;

  const BASE_FORM = {
    id: '', // 注文ID
    purchaseOrderNumber: '', // 発注番号
    shopName: '', // 店舗名
    serviceTypeCode: '', // サービスタイプコード
    serviceTypeName: '', // サービスタイプ名
    opportunityNumber: '', // 案件番号
    salesUserName: '', // Cqree営業担当名
    heldDate: '', // 開催日
    heldCharTime: '', // 開催時間
    joinNumber: '', // 参加人数
    totalPrice: '', // 合計金額
    customerBillPrice: '', // 顧客請求額
    refundPrice: '', // 返金対象額
    paymentMethodName: '', // 支払方法名
    deliveryRouteText: '', // 搬入経路テキスト
    deliveryAddress: '', // 納品先住所
    companyName: '', // 会社名
    branchName: '', // 支店営業所
    customerName: '', // お客様名
    customerPhone: '', // 電話番号
    driverName: '', // ドライバー名
    driverPhone: '', // ドライバー電話番号
    driverPickupTime: '', // ドライバーピックアップ時間
    opportunityShopRemarks: '', // 店舗向け備考
    productRemarks: '', // 商品内容備考
    customerRemarks: '', // ユーザー向け備考
    shopRemarks: '', // 店舗使用用備考
    shopPropriety: '', // 店舗受注可否
    shopProprietyStr: '', // 店舗受注可否(文字)
    shopProprietyRemarks: '', // 店舗受注可否コメント
    opportunityStatusName: '', // ステータス名
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(BASE_FORM);
  const [tempForm, setTempForm] = useState(form);

  const [isEdit, setIsEdit] = useState(!id);
  const data = useSelector((state) => state.opportunityDetailStore.data);
  const isSaveSuccess = useSelector((state) => state.opportunityDetailStore.isSaveSuccess);

  React.useEffect(() => {
    if (id && data) {
      let shopProprietyStr = '';
      if (data.shopPropriety === 1) shopProprietyStr = '可';
      if (data.shopPropriety === 0) shopProprietyStr = '否';
      const tmpData = {
        ...data,
        shopProprietyStr,
      };
      setForm(tmpData);
      setTempForm(tmpData);
    }
  }, [data]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      setIsEdit(false);
    }
  }, [isSaveSuccess]);

  const onEdit = () => {
    setIsEdit(true);
  };

  const onSave = () => {
    dispatch(opportunityDetailActions.saveData(tempForm));
  };

  const onEditCancel = () => {
    setIsEdit(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    const changeTempForm = {
      ...tempForm,
      [name]: value,
    };
    setTempForm(changeTempForm);
  };

  return (
    <>
      <Box
        mr={1}
        style={{
          minHeight: '48px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
        }}
      >
        {!isEdit && !isDisabled && (
          <Button variant="contained" size="small" onClick={onEdit}>編集</Button>
        )}
      </Box>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="ご自由にご利用ください" /></Box>
        <DataGrid title="メモ" isEdit={isEdit} value={form.shopRemarks} searchAttention="メモ等ご自由にお使いください">
          <TextArea value={tempForm.shopRemarks} name="shopRemarks" onChange={onChange} />
        </DataGrid>
        {isEdit && (
          <Box textAlign="center" p={1} borderTop="1px solid rgba(0, 0, 0, .125)">
            {id && (
            <Button color="inherit" variant="contained" size="small" onClick={onEditCancel} style={{ marginRight: '10px' }}>キャンセル</Button>
            )}
            <Button variant="contained" size="small" onClick={onSave}>保存</Button>
          </Box>
        )}
      </Paper>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="基本情報" /></Box>
        <DataGrid title="店舗名" value={form.shopName} />
        <DataGrid title="サービスタイプ名" value={form.serviceTypeName} />
        <DataGrid title="案件番号" value={form.opportunityNumber} />
        <DataGrid title="Cqree営業担当名" value={form.salesUserName} />
        <DataGrid title="店舗受注可否" value={form.shopProprietyStr} />
        <DataGrid title="店舗受注可否コメント" value={form.shopProprietyRemarks} />
        <DataGrid title="ステータス名" value={form.opportunityStatusName} />
        <DataGrid title="案件概要" value={data.overview} />
      </Paper>
      <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
        <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="注文情報" /></Box>
        <DataGrid title="開催日" value={form.heldDate} />
        <DataGrid title="開催時間" value={form.heldCharTime} />
        <DataGrid title="参加人数" value={form.joinNumber} />
        {data.orderId ? (
          <>
            <DataGrid title="合計金額" value={form.totalPrice} />
            <DataGrid title="顧客請求額" value={form.customerBillPrice} />
            <DataGrid title="返金対象額" value={form.refundPrice} />
            <DataGrid title="支払方法名" value={form.paymentMethodName} />
          </>
        )
          : (
            <>
              <DataGrid title="納品先住所" value={form.deliveryAddress} />
              <DataGrid title="会社名" value={form.companyName} />
            </>
          )}
      </Paper>
      {data.orderId && (
      <>
        <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
          <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="納品情報" /></Box>
          <DataGrid title="納品先住所" value={form.deliveryAddress} />
          <DataGrid title="搬入経路" value={form.deliveryRouteText} />
        </Paper>
        <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
          <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="ピックアップ時間" /></Box>
          <DataGrid title="ドライバー名" value={form.driverName} />
          <DataGrid title="ドライバー電話番号" value={form.driverPhone} />
          <DataGrid title="ドライバーピックアップ時間" value={form.driverPickupTime} />
        </Paper>
        <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
          <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="お客様名情報" /></Box>
          <DataGrid title="会社名" value={form.companyName} />
          {form.purchaseOrderNumber && (
          <>
            <DataGrid title="支店営業所" value={form.branchName} />
            <DataGrid title="お客様名" value={form.customerName} />
            <DataGrid title="電話番号" value={form.customerPhone} />
            {form.needMail && (
            <DataGrid title="メール" value={form.customerEmail} />
            )}
          </>
          )}
        </Paper>
        <Paper elevation={3} style={{ padding: '10px', marginBottom: 8 }}>
          <Box mb={3}><Chip sx={{ fontWeight: 'bold' }} label="備考情報" /></Box>
          <DataGrid title="店舗向け備考" value={form.opportunityShopRemarks} />
          <DataGrid title="商品内容備考" value={form.productRemarks} />
          <DataGrid title="ユーザー向け備考" value={form.customerRemarks} />
        </Paper>
      </>
      )}
    </>
  );
}

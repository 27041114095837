import { call, put, takeLatest } from 'redux-saga/effects';
import { shopAttachActions } from './shopAttachState';
import { REST_API } from '../../../constants/serverUrls';
import {
  getDatas, getDataById, saveData, deleteDatas,
} from '../../../services/base.service';

function* getPlanData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.PLAN.PLAN, payload);
    yield put(shopAttachActions.getPlanDataSuccess(result));
  } catch (_) { }
}

function* getHolidayData({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.SHOP_ATTACHED.HOLIDAY, payload);
    yield put(shopAttachActions.getHolidayDataSuccess(result));
  } catch (_) { }
}

function* getHolidayDataById({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.SHOP_ATTACHED.HOLIDAY_ID, payload);
    yield put(shopAttachActions.getHolidayDataByIdSuccess(result));
  } catch (_) { }
}

function* saveHolidayData({ payload }) {
  try {
    yield call(saveData, REST_API.SYSTEM_SETTING.SHOP_ATTACHED.HOLIDAY, payload);
    yield put(shopAttachActions.saveHolidayDataSuccess());
  } catch (_) { }
}

function* deleteHolidayData({ payload }) {
  try {
    yield call(deleteDatas, REST_API.SYSTEM_SETTING.SHOP_ATTACHED.HOLIDAY, payload);
    yield put(shopAttachActions.deleteHolidayDataSuccess());
  } catch (_) { }
}

function* shopAttachSaga() {
  yield takeLatest('shopAttach/getPlanData', getPlanData);
  yield takeLatest('shopAttach/getHolidayData', getHolidayData);
  yield takeLatest('shopAttach/getHolidayDataById', getHolidayDataById);
  yield takeLatest('shopAttach/saveHolidayData', saveHolidayData);
  yield takeLatest('shopAttach/deleteHolidayData', deleteHolidayData);
}

export default shopAttachSaga;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, RadioGroup, FormControlLabel, Radio,
  DialogContent, DialogActions, LinearProgress,
} from '@mui/material';
import {
  collection, addDoc, serverTimestamp,
} from 'firebase/firestore';
import { pdf } from '@react-pdf/renderer';
import db from '../../../firebase';
import { opportunityDetailActions } from '../../../redux/opportunity/opportunity/opportunityDetailState';
import Dialog from '../../../components/atoms/Dialog';
import {
  TextArea, FormErrorText, LoadingButton,
} from '../../../components/atoms/Base';
import DataGrid from '../../../components/templates/DataGrid';
import Validation from './validation';
import CommonValidation from '../../../utils/common.validation';
import { REST_API } from '../../../constants/serverUrls';
import ConfirmationFile from '../report/ConfirmationFile';
import ComfirmationAffiliationFile from '../report/ComfirmationAffiliationFile';
import PurchaseOrderFile from '../report/PurchaseOrderFile';
import HttpConnection from '../../../utils/http-connection';

const tytpes = [
  { value: 1, label: '可' },
  { value: 0, label: '否' },
];

const DEFAULT_ANSWER = {
  id: '',
  shopPropriety: '',
  shopProprietyRemarks: '',
};

const DEFAULT_ERROR_MESSAGE = {
  shopPropriety: '',
  shopProprietyRemarks: '',
};

const DEFAULT_MAILER_DATA = {
  to: '',
  cc: '',
  subject: '',
  body: '',
};

export default function CheckCompDialog(props) {
  const [answer, setAnswer] = React.useState(DEFAULT_ANSWER);
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGE);
  const [mailerData] = useState(DEFAULT_MAILER_DATA);
  const dbPdfData = useSelector((state) => state.opportunityDetailStore.pdfDatas);
  const [pdfData, setPdfData] = useState({ orderData: {}, puchaseOrderData: [] });
  const loading = useSelector((state) => state.commonStore.isLoading);
  const dispatch = useDispatch();
  const {
    id, isOpen, onClose, data, onSuccess,
  } = props;

  useEffect(() => {
    if (dbPdfData) {
      setPdfData(dbPdfData);
    }
  }, [dbPdfData]);

  useEffect(() => {
    if (isOpen) {
      dispatch(opportunityDetailActions.getPdfData({
        opportunityId: data.id, orderId: data.orderId,
      }));
    }
  }, [isOpen]);

  const updateAnswer = (e) => {
    const { name, value } = e.target;
    let tmpValue = value;
    if (name === 'shopPropriety') tmpValue = Number(value);
    let msg = '';
    if (answer.shopPropriety === 0) {
      msg = Validation.formValidate(name, value);
    }
    if (answer.shopPropriety !== 0 && name === 'shopPropriety') {
      msg = CommonValidation.requiredSelect(value);
    }
    setErrorMessages({ ...errorMessages, [name]: msg });
    setAnswer((prevAnswer) => ({ ...prevAnswer, [name]: tmpValue }));
  };

  const beforeSaveCheck = (newRow) => {
    let tempMessages = errorMessages;
    let isError = false;

    if (newRow.shopPropriety === 0) {
      Object.keys(newRow).forEach((key) => {
        const msg = Validation.formValidate(key, newRow[key]);
        if (msg) {
          isError = true;
        }
        tempMessages = {
          ...tempMessages,
          [key]: msg,
        };
      });
      setErrorMessages(tempMessages);
      return (isError);
    }

    Object.keys(newRow).forEach((key) => {
      if (key === 'shopPropriety') {
        const msg = CommonValidation.requiredSelect(newRow[key]);
        if (msg) {
          isError = true;
        }
        tempMessages = {
          ...tempMessages,
          [key]: msg,
        };
      }
    });
    setErrorMessages(tempMessages);
    return (isError);
  };

  const addMessageToFirestore = async (tmpAnswer) => {
    try {
      const dt = new Date();
      const dateString = `${dt.getFullYear()}年${dt.getMonth() + 1}月${dt.getDate()}日 ${dt.getHours()}時${dt.getMinutes()}分`;
      await addDoc(collection(db, 'messagesToAdmin'), {
        shopPropriety: tmpAnswer.shopPropriety,
        shopProprietyRemarks: tmpAnswer.shopProprietyRemarks,
        orderId: `${data.orderId}`,
        companyName: data.companyNameOpp,
        customerName: data.customerNameOpp,
        orderNumber: data.orderNumber,
        read: false,
        date: serverTimestamp(),
        dateString,
        shopName: data.shopName,
        salesUserId: data.salesUserId,
      });
    } catch (_) {}
  };

  const fileData = () => {
    if (!pdfData.orderData.header) return '';
    const returnData = !pdfData.orderData.header.affiliationId ? (
      <ConfirmationFile data={pdfData.orderData} />)
      : (<ComfirmationAffiliationFile data={pdfData.orderData} />);
    return returnData;
  };

  const generateAndSendPdf = async (formData, file, fileName, key) => {
    const document = pdf(file);
    const blob = await document.toBlob();
    formData.append(key, blob, `${fileName}.pdf`);
  };

  const upload = async (param) => {
    const formData = new FormData();
    await generateAndSendPdf(formData, fileData(), pdfData.orderData.header.fileName, 'orderFile');
    await generateAndSendPdf(
      formData,
      <PurchaseOrderFile data={pdfData.puchaseOrderData} />,
      pdfData.puchaseOrderData.header.fileName,
      'puchaseOrderFile',
    );
    formData.append('request', new Blob([JSON.stringify(param)], { type: 'application/json' }));
    const httpConnection = new HttpConnection();
    try {
      const result = await httpConnection.upload(REST_API.OPPORTUNITY.OPPORTUNITY.ANSWER, formData);
      return result;
    } catch (error) { return null; }
  };

  const onClickSave = async () => {
    if (beforeSaveCheck(answer) || !id) {
      return;
    }
    const tmpAnswer = {
      ...answer,
      orderId: data.orderId,
      ...mailerData,
      customerEmail: data.customerEmail,
      shopEmail: data.shopEmail,
      opportunityId: id,
    };
    const result = await upload(tmpAnswer);
    if (result == null) {
      return;
    }
    await addMessageToFirestore(tmpAnswer);
    onSuccess();
    setAnswer(DEFAULT_ANSWER);
  };

  return (
    <Dialog fullWidth isOpen={isOpen} onClose={onClose} title="回答">
      {loading && (<LinearProgress color="secondary" />)}
      <DialogContent dividers>
        <DataGrid title="受注可否" isRequired isEdit>
          <RadioGroup row value={answer.shopPropriety} name="shopPropriety" onChange={updateAnswer}>
            {tytpes.map((e) => (
              <FormControlLabel
                key={e.value}
                value={e.value}
                control={<Radio />}
                label={e.label}
              />
            ))}
          </RadioGroup>
          <FormErrorText>{errorMessages.shopPropriety}</FormErrorText>
        </DataGrid>
        <DataGrid title="コメント" isRequired={answer.shopPropriety === 0} isEdit>
          <TextArea
            name="shopProprietyRemarks"
            style={{ width: '100%' }}
            value={answer.shopProprietyRemarks}
            minRows={10}
            onChange={updateAnswer}
          />
          <FormErrorText>{errorMessages.shopProprietyRemarks}</FormErrorText>
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setAnswer(DEFAULT_ANSWER); onClose(); }} color="inherit">キャンセル</Button>
        <LoadingButton onClick={onClickSave}>回答する</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

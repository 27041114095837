import { createSlice } from '@reduxjs/toolkit';

export const opportunityDetailSlice = createSlice({
  name: 'opportunityDetail',
  initialState: {
    data: {},
  },
  reducers: {

    getData: (state) => {
      state.data = {};
    },
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    saveData: (state) => {
      state.isSaveSuccess = false;
    },
    saveDataSuccess: (state, action) => {
      state.isSaveSuccess = true;
      state.saveId = action.payload;
    },
    getOrderDetails: () => {},
    getOrderDetailsSuccess: (state, action) => {
      state.details = action.payload;
    },
    getEstimateDetails: () => {},
    getEstimateDetailsSuccess: (state, action) => {
      state.estimates = action.payload;
    },
    getPdfData: () => {},
    getPdfDataSuccess: (state, action) => {
      state.pdfDatas = action.payload;
    },
    resetFlg: (state) => {
      state.isSaveSuccess = false;
    },
    getDeliveryDatas: () => {},
    getDeliveryDatasSuccess: (state, action) => {
      state.deliveryDatas = action.payload;
    },
  },
});

export const opportunityDetailActions = opportunityDetailSlice.actions;

export default opportunityDetailSlice.reducer;

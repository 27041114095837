import { call, put, takeLatest } from 'redux-saga/effects';
import { shopActions } from './shopState';
import { REST_API } from '../../../constants/serverUrls';
import { getDatas, getDataById, updateData } from '../../../services/base.service';

function* getShops({ payload }) {
  try {
    const result = yield call(getDatas, REST_API.SYSTEM_SETTING.SHOP.SHOP, payload);
    yield put(shopActions.getDatasSuccess(result));
  } catch (_) { }
}

function* getData({ payload }) {
  try {
    const result = yield call(getDataById, REST_API.SYSTEM_SETTING.SHOP.SHOP_ID, payload);
    yield put(shopActions.getDataSuccess(result));
  } catch (_) { }
}

function* shopUpdate({ payload }) {
  try {
    yield call(updateData, REST_API.SYSTEM_SETTING.SHOP.SHOP_ID, payload);
    yield put(shopActions.updateDataSuccess());
  } catch (_) { }
}

function* shopSaga() {
  yield takeLatest('shop/getDatas', getShops);
  yield takeLatest('shop/getData', getData);
  yield takeLatest('shop/updateData', shopUpdate);
}

export default shopSaga;

import React from 'react';
import { Grid } from '@mui/material';
import Notice from './Notice';
import Todo from './Todo';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Todo />
        </Grid>
        <Grid item xs={12} md={6}>
          <Notice />
        </Grid>
      </Grid>
    );
  }
}

export default Home;

import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import ShopDetailInfoCard from './ShopDetailInfoCard';
import { TabPanel, withParams } from '../../../components/atoms/Base';
import PlanDataTable from './plan/PlanDataTable';
import { shopActions } from '../../../redux/systemSetting/shop/shopState';
import HolidayDataTable from './holiday/HolidayDataTable';
import Tabs from '../../../components/templates/Tabs';

class ShopDetail extends React.Component {
  constructor(props) {
    super(props);

    const { id } = this.props.params;

    const searchCondition = {
      shopId: id,
      page: 0,
      records: 50,
      order: 'asc',
      orderBy: '',
      deliverySearchCondition: {
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      },
      holidaySearchCondition: {
        page: 0,
        records: 50,
        order: 'asc',
        orderBy: '',
      },
    };

    this.props.dispatch(shopActions.getData({ id }));
    this.state = {
      searchCondition,
      tabValue: 0,
      id,
    };
  }

  onTabChange = (_, tabValue) => {
    this.setState({ tabValue });
  };

  render() {
    const {
      searchCondition,
      tabValue,
      id,
    } = this.state;

    return (
      <Grid container spacing={1} mb={2}>
        <Grid item xs={12} md={6}>
          <ShopDetailInfoCard shopId={id} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Tabs
            value={tabValue}
            onChange={this.onTabChange}
            tabs={['プラン', '休業']}
          />
          <TabPanel value={tabValue} index={0}>
            <PlanDataTable shopId={searchCondition.shopId} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <HolidayDataTable shopId={searchCondition.shopId} />
          </TabPanel>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  shopInfo: state.shopStore,
});

export default withParams(connect(mapStateToProps)(ShopDetail));

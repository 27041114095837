import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button, Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector } from 'react-redux';
import Dialog from '../../../components/atoms/Dialog';

export default function ConfirmDialog(props) {
  const isLoading = useSelector((state) => state.commonStore.isLoading);
  const {
    onClick,
    open,
    onClose,
  } = props;

  return (
    <Dialog isOpen={open} onClose={onClose} title="画像アップロード完了">
      <DialogContent dividers>
        <Box>実例画像のアップロードを完了します。</Box>
        <Box>よろしいですか？</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <LoadingButton
          onClick={onClick}
          loading={isLoading}
          color="primary"
        >
          完了する
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

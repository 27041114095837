import { createSlice } from '@reduxjs/toolkit';

export const opportunityFileSlice = createSlice({
  name: 'opportunityFile',
  initialState: {
    isDeleteSuccess: false,
  },
  reducers: {
    resetFlg: (state) => {
      state.isDeleteSuccess = false;
      state.isInsertSuccess = false;
      state.isUpdateSuccess = false;
    },
    getDatas: () => {},
    getDatasSuccess: (state, action) => {
      state.datas = action.payload;
    },
    getData: () => {},
    getDataSuccess: (state, action) => {
      state.data = action.payload;
    },
    deleteData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    getUrl: (state) => {
      state.url = null;
    },
    getUrlSuccess: (state, action) => {
      state.url = action.payload;
    },
    insertData: (state) => {
      state.isInsertSuccess = false;
    },
    insertDataSuccess: (state) => {
      state.isInsertSuccess = true;
    },
    updateData: (state) => {
      state.isUpdateSuccess = false;
    },
    updateDataSuccess: (state) => {
      state.isUpdateSuccess = true;
    },
  },
});

export const opportunityFileActions = opportunityFileSlice.actions;

export default opportunityFileSlice.reducer;

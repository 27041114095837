import React from 'react';

import OpportunityList from '../pages/opportunity/list/OpportunityList';
import CalendarList from '../pages/opportunity/list/CalendarList';
import OpportunityDetail from '../pages/opportunity/opportunity/OpportunityDetail';

import ShopList from '../pages/systemSetting/shop/ShopList';
import ShopDetail from '../pages/systemSetting/shop/ShopDetail';
import ReviewList from '../pages/systemSetting/review/ReviewList';
import ReviewDetail from '../pages/systemSetting/review/ReviewDetail';
import LoginUserList from '../pages/systemSetting/loginUser/LoginUserList';
import LoginUserDetail from '../pages/systemSetting/loginUser/LoginUserDetail';
import FilePreview from '../pages/opportunity/file/FilePreview';

const detailUrl = {
  OPPORTUNITY_DETAIL: '/opportunity/',
  ESTIMATE_DETAIL: '/estimate/',
  OPPORTUNITY_NO_COMFIRM_DETAIL: '/opportunity-no-confirm/detail/',
  OPPORTUNITY_WAITING_DETAIL: '/opportunity-waiting/detail/',
  OPPORTUNITY_COMPLETE_DETAIL: '/opportunity-complete/detail/',
  OPPORTUNITY_CANCEL_DETAIL: '/opportunity-cancel/detail/',
  OPPORTUNITY_CALENDAR_DETAIL: '/calendar/detail/',
  SHOP_DETAIL: '/shop/detail/',
  REVIEW_DETAIL: '/review/detail/',
  LOGIN_USER_DETAIL: '/login-user/detail/',
  FILE: '/file/',
  DELIVERY_FILE: '/delivery-file/',
};
export default detailUrl;

export const urls = {
  COMMON: {
    HOME: { name: 'home', url: '/' },
    LOGIN: { name: 'ログイン', url: '/login' },
    SYSTEM_ERROR: { name: 'エラー', url: '/error' },
  },
  OPPORTUNITY: [
    {
      name: '未確定', url: '/opportunity-no-confirm', component: <OpportunityList screenId="1" statuses={[1, 2]} url={detailUrl.OPPORTUNITY_NO_COMFIRM_DETAIL} />, isMenuDisplay: true,
    },
    {
      name: '配達待ち', url: '/opportunity-waiting', component: <OpportunityList screenId="2" statuses={[3]} url={detailUrl.OPPORTUNITY_WAITING_DETAIL} />, isMenuDisplay: true,
    },
    {
      name: '配達完了', url: '/opportunity-complete', component: <OpportunityList screenId="3" statuses={[99]} url={detailUrl.OPPORTUNITY_COMPLETE_DETAIL} />, isMenuDisplay: true,
    },
    {
      name: 'キャンセル', url: '/opportunity-cancel', component: <OpportunityList screenId="4" statuses={[98]} url={detailUrl.OPPORTUNITY_CANCEL_DETAIL} />, isMenuDisplay: true,
    },
    {
      name: 'カレンダー', url: '/calendar', component: <CalendarList />, isMenuDisplay: true,
    },
    { name: '見積詳細', url: `${detailUrl.ESTIMATE_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_NO_COMFIRM_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_WAITING_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_COMPLETE_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_CANCEL_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: '案件詳細', url: `${detailUrl.OPPORTUNITY_CALENDAR_DETAIL}:id`, component: <OpportunityDetail /> },
    { name: 'ファイル', url: `${detailUrl.FILE}:id`, component: <FilePreview delivery={false} /> },
    { name: 'ファイル', url: `${detailUrl.DELIVERY_FILE}:id`, component: <FilePreview delivery /> },
  ],
  SYSTEM_SETTING: [
    {
      name: '店舗', url: '/shop', component: <ShopList />, isMenuDisplay: true,
    },
    { name: '店舗詳細', url: `${detailUrl.SHOP_DETAIL}:id`, component: <ShopDetail /> },
    {
      name: '口コミ', url: '/review', isMenuDisplay: true, component: <ReviewList />,
    },
    { name: '口コミ詳細', url: `${detailUrl.REVIEW_DETAIL}:id`, component: <ReviewDetail /> },
    {
      name: 'ログインユーザー', url: '/login-user', isMenuDisplay: true, component: <LoginUserList />,
    },
    { name: 'ログインユーザー詳細', url: detailUrl.LOGIN_USER_DETAIL, component: <LoginUserDetail /> },
    { name: 'ログインユーザー詳細', url: `${detailUrl.LOGIN_USER_DETAIL}:id`, component: <LoginUserDetail /> },
  ],
  PROFILE: {
    PASSWORD: { name: 'パスワード変更', url: '/change-password' },
  },
};

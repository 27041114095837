import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ListItem, ListItemText, ListItemButton,
} from '@mui/material';

export default function HeaderMenuItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    url, menuName, onClose, color,
  } = props;

  const onClick = (_, link) => {
    onClose();
    navigate(link);
  };

  const path = `/${location.pathname.split('/')[1]}`;

  return (
    <ListItem
      sx={{ padding: 0 }}
      onClick={(event) => onClick(event, url)}
    >
      <ListItemButton selected={url === path} style={{ paddingLeft: '25px' }}>
        <ListItemText
          primary={menuName}
          primaryTypographyProps={{
            color: color || '',
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

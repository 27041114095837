import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, Box, Paper, TextField,
} from '@mui/material';
import { reviewActions } from '../../../redux/systemSetting/review/reviewState';
import {
  withParams, RatingStar, Link, FormTitle,
} from '../../../components/atoms/Base';
import ImageList from '../../../components/templates/imageList/ImageList';
import DataGrid from '../../../components/templates/DataGrid';
import detailUrl from '../../../constants/frontUrls';

class ReviewDetail extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;

    this.state = {
      form: {},
      itemDatas: [],
    };
    if (id) {
      props.dispatch(reviewActions.getData({ id }));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reviewInfo.data !== this.props.reviewInfo.data) {
      if (this.props.reviewInfo.data) {
        this.setData(this.props.reviewInfo.data);
      }
    }
  }

  setData = (data) => {
    this.setState({ form: data.reviewDetail, itemDatas: data.images });
  };

  render() {
    const {
      form,
      itemDatas,
    } = this.state;

    return (
      <Box mb={2}>
        <Paper elevation={3} style={{ padding: '10px' }}>
          <DataGrid
            title="投稿者名"
            value={form.customerName}
          />
          <DataGrid
            title="案件"
            value={(
              <Link to={detailUrl.OPPORTUNITY_DETAIL + form.orderId}>
                {form.purchaseOrderNumber}
              </Link>
            )}
          />
          <DataGrid
            title="店舗"
            isRequired
            value={(
              <Link to={detailUrl.SHOP_DETAIL + form.shopId}>
                {form.shopName}
              </Link>
            )}
          />
          {form.evaluationPublished && (
            <Grid container spacing={1} mb={2} style={{ wordBreak: 'break-all' }}>
              <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormTitle title="評価" />
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1} mb={2.5} style={{ wordBreak: 'break-all' }}>

                  <Grid item xs={12} md={1.5} sx={{ display: 'flex' }}>
                    <Box style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    >
                      <Box>平均</Box>
                      <Box style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>{form.averageEvaluation}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={10.5}>
                    <Grid container style={{ wordBreak: 'break-all' }}>
                      <Grid item xs={3} md={1.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <Box>味</Box>
                      </Grid>
                      <Grid item xs={9} md={10.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <RatingStar
                          name="tasteEvaluation"
                          value={form.tasteEvaluation}
                          readOnly
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ wordBreak: 'break-all' }}>
                      <Grid item xs={3} md={1.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <Box display="flex">ボリューム</Box>
                      </Grid>
                      <Grid item xs={9} md={10.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <RatingStar
                          name="volumeEvaluation"
                          value={form.volumeEvaluation}
                          readOnly
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ wordBreak: 'break-all' }}>
                      <Grid item xs={3} md={1.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <Box display="flex">色どり</Box>
                      </Grid>
                      <Grid item xs={9} md={10.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <RatingStar
                          name="colorfulEvaluation"
                          value={form.colorfulEvaluation}
                          readOnly
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {form.commentPublished && (
            <DataGrid title="コメント" value={form.comment} />
          )}
          {form.imagePublished && (
            <DataGrid
              title="画像"
              value={(
                <ImageList
                  itemDatas={itemDatas}
                />
              )}
            >
              <TextField />
            </DataGrid>
          )}
          <DataGrid title="公開日" value={form.publishDate}>
            <TextField />
          </DataGrid>
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewInfo: state.reviewStore,
  loginUser: state.loginStore,
});

export default withParams(connect(mapStateToProps)(ReviewDetail));

import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import HttpConnection from '../../utils/http-connection';
import { REST_URL } from '../../constants/serverUrls';

export default function DataSelectList(props) {
  const {
    error,
    value,
    onChange,
    disableClearable,
    isDisabled,
    name,
    target,
    parameter,
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const httpConnection = new HttpConnection(true);
    const tmpData = await httpConnection.get(`${REST_URL}/select/${target}`, parameter);
    setOptions(tmpData);
    setLoading(false);
  };

  useEffect(() => {
    if (name) {
      fetchData();
    }
  }, [name]);

  const targetValues = options ? options.filter((row) => row.id === value) : [];
  let [targetValue] = targetValues;

  targetValue = targetValue ?? null;

  return (
    <Autocomplete
      style={{ minWidth: '200px' }}
      size="small"
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      loading={loading}
      renderInput={(params) => <TextField {...params} error={error} />}
      value={targetValue}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue && selectValue.id,
            label: selectValue && selectValue.name,
            unitPrice: selectValue && selectValue.price,
            taxRate: selectValue && selectValue.taxRate,
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import { REST_API } from '../../constants/serverUrls';
import { homeActions } from './homeState';
import { getDatas, getDataById } from '../../services/base.service';

function* getTodo() {
  const result = yield call(getDatas, REST_API.HOME.TODO);
  yield put(homeActions.getTodoSuccess(result));
}

function* getNotice() {
  const result = yield call(getDatas, REST_API.HOME.NOTICE);
  yield put(homeActions.getNoticeSuccess(result));
}

function* getNoticeId({ payload }) {
  const result = yield call(getDataById, REST_API.HOME.NOTICE_ID, payload);
  yield put(homeActions.getNoticeIdSuccess(result));
}

function* homeSaga() {
  yield takeLatest('home/getTodo', getTodo);
  yield takeLatest('home/getNotice', getNotice);
  yield takeLatest('home/getNoticeId', getNoticeId);
}

export default homeSaga;

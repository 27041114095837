import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  Grid, Typography, Box, TextField, Button, CircularProgress, CssBaseline,
  Backdrop, Link, InputAdornment, IconButton, OutlinedInput, Paper,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginActions } from '../../redux/login/loginState';
import img from '../../asset/bg_main.jpg';

class Login extends React.Component {
  constructor(props) {
    super(props);
    document.cookie = 'jwt=; path=/; max-age=0';
    localStorage.clear();
    props.dispatch(loginActions.clearLoginInfo());
    this.state = {
      info: {
        loginId: '',
        password: '',
      },
      showPassword: false,
      isSuccess: false,
      message: '',
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginInfo !== this.props.loginInfo) {
      if (this.props.loginInfo.isLoginSuccess) {
        const { token } = this.props.loginInfo;
        document.cookie = `jwt=${token}; path=/; max-age=86400`;
        this.setMessage(null, true);
      }
      if (this.props.loginInfo.isLoginSuccess === false) {
        this.setMessage('メールアドレスまたはパスワードが間違っています', false);
      }
    }
  }

  setMessage = (message, isSuccess) => {
    this.setState({ loading: isSuccess, message, isSuccess });
  };

  handleBtnLogin = (event) => {
    this.setState({ loading: true });

    event.preventDefault();
    const { info } = this.state;

    if (!info.loginId || !info.password) {
      return;
    }

    this.setState({ loading: true });
    this.props.dispatch(
      loginActions.executeLogin({
        email: info.loginId,
        password: info.password,
      }),
    );
  };

  onChange = (event) => {
    const key = event.target.name;
    const { value } = event.target;
    const { info } = this.state;
    this.setState({ info: { ...info, [key]: value } });
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  // eslint-disable-next-line class-methods-use-this
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const {
      info, message, loading, isSuccess, showPassword,
    } = this.state;

    return (
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            position: 'relative',
            backgroundImage: `url(${img})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#fafafa',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {isSuccess && (
            <Navigate to="/" replace />
          )}
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box color="red" mt={2}>{message}</Box>
            <Box component="form" onSubmit={this.handleBtnLogin} noValidate sx={{ mt: 1 }}>
              <Box>メールアドレス</Box>
              <TextField
                value={info.loginId}
                required
                fullWidth
                id="loginId"
                name="loginId"
                autoComplete="loginId"
                autoFocus
                onChange={this.onChange}
              />
              <Box mt={2}>パスワード</Box>
              <OutlinedInput
                required
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={info.password}
                name="password"
                id="password"
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
                autoComplete="current-password"
                onChange={this.onChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                ログイン
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontSize: '13px',
                }}
              >
                <Link href="/reset-password">
                  パスワードを忘れた方
                </Link>
              </Box>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="body2" color="text.secondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://www.cqree.jp/corporate/">
                Cqree INC.
              </Link>
              {' '}
              {new Date().getFullYear()}
              .
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loginInfo: state.loginStore,
});

export default connect(mapStateToProps)(Login);

import React from 'react';
import {
  DialogContent, DialogActions, Button,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '../../../../components/atoms/Dialog';
import { shopAttachActions } from '../../../../redux/systemSetting/shopAttach/shopAttachState';
import DataGrid from '../../../../components/templates/DataGrid';
import {
  DatePicker, FormErrorText, TextArea,
} from '../../../../components/atoms/Base';
import Validation from './validation';
import DataSelectList from '../../../../components/atoms/DataSelectList';

const DEFAULT_FORM = {
  id: '',
  serviceTypeCode: '',
  holidayTypeCode: '',
  startDate: '',
  endDate: '',
  remarks: '',
};

export default function HolidayDialog(props) {
  const {
    isOpen,
    onClose,
    openedMenuRowId,
    shopId,
  } = props;
  const dispatch = useDispatch();
  const [form, setForm] = React.useState(DEFAULT_FORM);
  const holiday = useSelector((state) => state.shopAttachStore.holiday);
  const [title, setTitle] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState({
    serviceTypeCode: '',
    holidayTypeCode: '',
    startDate: '',
    endDate: '',
  });

  React.useEffect(() => {
    if (isOpen && openedMenuRowId) {
      setTitle('休業の編集');
      dispatch(shopAttachActions.getHolidayDataById({ id: openedMenuRowId }));
    }
    if (isOpen && !openedMenuRowId) {
      setTitle('休業の追加');
      setForm(DEFAULT_FORM);
    }
    if (isOpen) {
      setErrorMessages({
        serviceTypeCode: '',
        holidayTypeCode: '',
        startDate: '',
        endDate: '',
      });
    }
  }, [isOpen]);
  React.useEffect(() => {
    if (holiday) {
      setForm(holiday);
    }
  }, [holiday]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages,
      [name]: Validation.formValidate(name, value),
    });
  };

  const beforeSaveCheck = () => {
    let tempMessage = errorMessages;
    let isError = false;

    Object.keys(form).forEach((key) => {
      let msg = Validation.formValidate(key, form[key]);
      if (msg) {
        isError = true;
      }
      if (!msg && key === 'endDate' && form.startDate > form.endDate) {
        msg = '開始日より未来の日付を入力してください';
      }
      tempMessage = {
        ...tempMessage,
        [key]: msg,
      };
    });

    if (isError) {
      setErrorMessages(tempMessage);
    }
    return isError;
  };

  const onSave = () => {
    if (beforeSaveCheck()) {
      return;
    }

    dispatch(shopAttachActions.saveHolidayData({
      ...form,
      shopId,
    }));
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <DialogContent dividers>
        <DataGrid title="サービスタイプ" isRequired isEdit>
          <DataSelectList
            name="serviceTypeCode"
            target="codes"
            error={!!errorMessages.serviceTypeCode}
            parameter={{ codeCategory: 'service_type' }}
            value={form.serviceTypeCode}
            onChange={onChange}
            disableClearable
          />
          <FormErrorText>{errorMessages.serviceTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="休日タイプ" isRequired isEdit>
          <DataSelectList
            name="holidayTypeCode"
            target="codes"
            error={!!errorMessages.holidayTypeCode}
            parameter={{ codeCategory: 'holiday_type' }}
            value={form.holidayTypeCode}
            onChange={onChange}
            disableClearable
          />
          <FormErrorText>{errorMessages.holidayTypeCode}</FormErrorText>
        </DataGrid>
        <DataGrid title="休日開始日" isRequired isEdit>
          <DatePicker
            error={!!errorMessages.startDate}
            value={form.startDate}
            onChange={onChange}
            name="startDate"
          />
          <FormErrorText>{errorMessages.startDate}</FormErrorText>
        </DataGrid>
        <DataGrid title="休日終了日" isRequired isEdit>
          <DatePicker
            error={!!errorMessages.endDate}
            value={form.endDate}
            onChange={onChange}
            name="endDate"
          />
          <FormErrorText>{errorMessages.endDate}</FormErrorText>
        </DataGrid>
        <DataGrid title="メモ" isEdit>
          <TextArea value={form.remarks} fullWidth name="remarks" onChange={onChange} />
        </DataGrid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">キャンセル</Button>
        <Button onClick={onSave}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { opportunityDetailActions } from '../../../redux/opportunity/opportunity/opportunityDetailState';
import DataTable from '../../../components/templates/dataTable/DataTable';
import detailUrl from '../../../constants/frontUrls';

const headCells = [
  { id: 'fileName', label: '件名', sortId: 'file_name' },
  { id: 'createdAt', label: '作成日', sortId: 'created_at' },
  { id: 'createdBy', label: '作成者', sortId: 'created_by' },
];

export default function DeliveryFileTable(props) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const fileDatas = useSelector((state) => state.opportunityDetailStore.deliveryDatas);
  const {
    isNotEdit, isNotDelete,
    id,
  } = props;

  useEffect(() => {
    if (id) {
      dispatch(opportunityDetailActions.getDeliveryDatas({ id }));
    }
  }, [id]);

  React.useEffect(() => {
    if (fileDatas) {
      setData(fileDatas);
    }
  }, [fileDatas]);

  const getFile = (row) => {
    const pageUrl = detailUrl.DELIVERY_FILE + row.id;
    window.open(pageUrl, '_blank');
  };

  return (
    <div>
      <DataTable
        rows={data || []}
        headCells={headCells}
        procName="ファイル"
        totalElements={data.totalElements}
        onOpen={getFile}
        isNotEdit={isNotEdit}
        isNotDelete={isNotDelete}
        isNoPagination
      />
    </div>
  );
}

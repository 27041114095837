import React from 'react';
import { connect } from 'react-redux';
import {
  Grid, TextField, Box,
} from '@mui/material';
import { reviewActions } from '../../../redux/systemSetting/review/reviewState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTable from '../../../components/templates/dataTable/DataTable';
import detailUrl from '../../../constants/frontUrls';
import { FormSwitch, FormTitle } from '../../../components/atoms/Base';
import NumberInput from '../../../components/atoms/NumberInput';

const DEFAULT_SEARCH_CONDITION = {
  shopName: '',
  planName: '',
  imageExisted: false,
  commentExisted: false,
  tasteEvaluationStart: '',
  tasteEvaluationEnd: '',
  volumeEvaluationStart: '',
  volumeEvaluationEnd: '',
  colorfulEvaluationStart: '',
  colorfulEvaluationEnd: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

class ReviewList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'title', label: 'タイトル', sortId: 'title' },
      { id: 'customerName', label: '投稿者名', sortId: 'customer_name' },
      { id: 'averageEvaluation', label: '平均評価', sortId: 'average_evaluation' },
      { id: 'commentExisted', label: 'コメント', sortId: 'comment_existed' },
      { id: 'imageExisted', label: '画像', sortId: 'image_existed' },
      { id: 'publishDate', label: '公開日', sortId: 'publish_date' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;
    if (props.reviewInfo.searchCondition) {
      searchCondition = props.reviewInfo.searchCondition;
    }

    this.state = {
      datas: {},
      searchCondition,
    };
    this.props.dispatch(reviewActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reviewInfo.datas !== this.props.reviewInfo.datas) {
      if (this.props.reviewInfo.datas) {
        this.setDatas(this.props.reviewInfo.datas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  search = (searchCondition) => {
    this.props.dispatch(reviewActions.setSearchCondition(searchCondition));
    this.props.dispatch(reviewActions.getDatas(searchCondition));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;

    const tempCondition = {
      ...searchCondition,
      [name]: value,
      page: 0,
    };

    this.setState({ searchCondition: tempCondition });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const { datas, searchCondition } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          condition={searchCondition}
          targetScreen="review"
          reSearch={this.reSearch}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormTitle title="店舗" />
              <TextField
                value={searchCondition.shopName}
                fullWidth
                name="shopName"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTitle title="プラン名" />
              <TextField
                value={searchCondition.planName}
                fullWidth
                name="planName"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTitle title="画像" />
              <FormSwitch
                label="画像あり"
                checked={searchCondition.imageExisted}
                onChange={this.onChange}
                name="imageExisted"
              />
            </Grid>
            <Grid item xs={6}>
              <FormTitle title="コメント" />
              <FormSwitch
                checked={searchCondition.commentExisted}
                onChange={this.onChange}
                name="commentExisted"
                label="コメントあり"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTitle title="味" />
              <Box display="flex" alignItems="center">
                <Box>
                  <NumberInput value={searchCondition.tasteEvaluationStart} fullWidth name="tasteEvaluationStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <NumberInput value={searchCondition.tasteEvaluationEnd} fullWidth name="tasteEvaluationEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTitle title="ボリューム" />
              <Box display="flex" alignItems="center">
                <Box>
                  <NumberInput value={searchCondition.volumeEvaluationStart} fullWidth name="volumeEvaluationStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <NumberInput value={searchCondition.volumeEvaluationEnd} fullWidth name="volumeEvaluationEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTitle title="色どり" />
              <Box display="flex" alignItems="center">
                <Box>
                  <NumberInput value={searchCondition.colorfulEvaluationStart} fullWidth name="colorfulEvaluationStart" onChange={this.onChange} />
                </Box>
                <Box px={2}>～</Box>
                <Box>
                  <NumberInput value={searchCondition.colorfulEvaluationEnd} fullWidth name="colorfulEvaluationEnd" onChange={this.onChange} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SearchBox>
        <DataTable
          url={detailUrl.REVIEW_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements || 0}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewInfo: state.reviewStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(ReviewList);

import { createSlice } from '@reduxjs/toolkit';

export const shopAttachSlice = createSlice({
  name: 'shopAttach',
  initialState: {

  },
  reducers: {
    getPlanData: () => { },
    getPlanDataSuccess: (state, action) => {
      state.plans = action.payload;
    },
    getHolidayData: () => { },
    getHolidayDataSuccess: (state, action) => {
      state.holidayDatas = action.payload;
    },
    getHolidayDataById: () => { },
    getHolidayDataByIdSuccess: (state, action) => {
      state.holiday = action.payload;
    },
    saveHolidayData: (state) => {
      state.isSaveSuccess = false;
    },
    saveHolidayDataSuccess: (state) => {
      state.isSaveSuccess = true;
    },
    deleteHolidayData: (state) => {
      state.isDeleteSuccess = false;
    },
    deleteHolidayDataSuccess: (state) => {
      state.isDeleteSuccess = true;
    },
    resetFlgs: (state) => {
      state.isSaveSuccess = false;
      state.isDeleteSuccess = false;
    },
  },
});

export const shopAttachActions = shopAttachSlice.actions;

export default shopAttachSlice.reducer;

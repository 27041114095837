import { isValid } from 'date-fns';
import { convertStringToList } from './common.helper';

export default class CommonValidation {
  /**
   * 必須チェック
   * @param {*} value
   * @returns
   */
  static requiredValue = (value) => {
    if (!value && value !== 0) return '入力してください';
    return '';
  };

  /**
   * 選択リスト用必須チェック
   * @param {*} value
   * @returns
   */
  static requiredSelect = (value) => {
    if (!value && value !== 0) return '選択してください';
    return '';
  };

  /**
   * 配列要素数0件チェック
   * @param {*} value
   * @returns
   */
  static requiredArray = (value) => {
    if (value.length === 0) return '選択してください';
    return '';
  };

  /**
   * 0未満数値チェック
   * @param {*} value 数値
   * @returns
   */
  static requiredNumber = (value) => {
    let message = '';
    message = CommonValidation.requiredValue(value);
    if (message) return message;
    const numericValue = typeof value === 'number' ? value.toString() : value;
    if (Math.sign(numericValue) < 1) return '1以上の値で入力してください';
    return '';
  };

  /**
   * 日付形式チェック
   * @param {*} value 日付
   * @returns
   */
  static nonRequiredDate = (value) => {
    if (!value) return '';
    if (!isValid(new Date(value))) return '日付形式で入力してください';
    return '';
  };

  /**
   * 日付形式チェック
   * @param {*} value 日付
   * @returns
   */
  static requiredDate = (value) => {
    let message = '';
    message = CommonValidation.requiredValue(value);
    if (message) return message;

    return CommonValidation.nonRequiredDate(value);
  };

  /**
   * メールアドレス形式チェック
   * @param {*} value メールアドレス
   * @returns
   */
  static requiredEmail = (value) => {
    let message = '';
    message = CommonValidation.requiredValue(value);
    if (message) return message;

    return CommonValidation.nonRequiredEmail(value);
  };

  /**
   * メールアドレス形式チェック
   * @param {*} value メールアドレス
   * @returns
   */
  static nonRequiredEmail = (value) => {
    if (!value) return '';
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    if (!regex.test(value)) return '正しい形式でメールアドレスを入力してください';
    return '';
  };

  /**
   * 電話番号チェック
   * @param {*} value
   * @returns
   */
  static nonRequiredPhoneNumber = (value) => {
    if (!value) return '';
    const regex = /^[0-9ー-]+$/;
    if (!regex.test(value)) return '電場番号は半角数字と「-」または「ー」のみで入力してください';
    return '';
  };

  /**
   * パスワード形式チェック
   * @param {*} value パスワード
   * @returns
   */
  static requiredPassword = (value) => {
    if (!value) return '入力してください';
    const regex = /^[A-Za-z\d@$#!._]{8,16}$/i;
    if (!regex.test(value)) return '8文字以上16文字以下で半角英数字、記号( ! # $ @ . _)のみで入力してください';
    return '';
  };

  /**
   * パスワード一致チェック
   * @param {*} password パスワード
   * @param {*} passwordCheck 確認パスワード
   * @returns
   */
  static requiredPasswordCheck = (password, passwordCheck) => {
    let message = '';
    message = CommonValidation.requiredValue(passwordCheck);
    if (message) return message;

    if (password !== passwordCheck) return 'パスワードが一致しません';
    return '';
  };

  /**
 * 複数メールアドレス形式チェック
 * @param {*} value カンマ区切りの複数メールアドレス (例：xxx@zzz.com, yyy@zzz.com... )
 * @returns
 */
  static requiredEmailList = (value) => {
    let message = '';
    message = CommonValidation.requiredValue(value);
    if (message) return message;

    return CommonValidation.nonRequiredEmailList(value);
  };

  /**
   * 複数メールアドレス形式チェック
   * @param {*} value カンマ区切りの複数メールアドレス (例：xxx@zzz.com, yyy@zzz.com... )
   * @returns
   */
  static nonRequiredEmailList = (value) => {
    let message = '';
    if (value && value.includes(',')) {
      const emailList = convertStringToList(value);
      emailList.forEach((email) => {
        if (message) {
          return;
        }
        message = CommonValidation.nonRequiredEmail(email);
      });
    } else {
      message = CommonValidation.nonRequiredEmail(value);
    }
    return message;
  };

  /**
   * Mailer呼び出し用
   * @param type（to, cc, subject, body)
   * @param value
   * @returns
   */
  static mailerDatasValidate = (type, value) => {
    switch (type) {
      case 'subject':
      case 'body':
        return CommonValidation.requiredValue(value);
      case 'to':
        return CommonValidation.requiredEmailList(value);
      case 'cc':
        return CommonValidation.nonRequiredEmailList(value);
      default:
        return '';
    }
  };

  /**
   * 商品選択チェック
   * @param {*} form
   * @param {*} errorMessages
   * @returns { tempMessage, isError }
   */
  static productValidate = (form, errorMessages) => {
    let name = '';
    let msg = '';
    switch (form.productInputTypeCode) {
      case 1:
        name = 'planId';
        msg = CommonValidation.requiredSelect(form[name]);
        break;
      case 2:
        name = 'freeProductName';
        msg = CommonValidation.requiredValue(form[name]);
        break;
      case 3:
        name = 'shopPlanOptionId';
        msg = CommonValidation.requiredSelect(form[name]);
        break;
      default:
        name = '';
    }
    const tempMessage = { ...errorMessages, [name]: msg };
    return { tempMessage, isError: !!msg };
  };

  /**
   * 店舗業者選択チェック
   * @param {*} form
   * @param {*} errorMessages
   * @returns { tempMessage, isError }
   */
  static storeValidate = (form, errorMessages, type) => {
    let name = '';
    let msg = '';
    switch (type) {
      case 1:
        name = 'shopId';
        msg = CommonValidation.requiredSelect(form[name]);
        break;
      case 2:
        name = 'vendorId';
        msg = CommonValidation.requiredSelect(form[name]);
        break;
      default:
        name = '';
    }
    const tempMessage = { ...errorMessages, [name]: msg };
    return { tempMessage, isError: !!msg };
  };
}

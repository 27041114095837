import React from 'react';
import { RichTextReadOnly as MuiRichText } from 'mui-tiptap';
import useExtensions from './useExtensions';

export default function RichTextReadOnly(props) {
  const extensions = useExtensions({
    placeholder: 'お知らせの詳細...',
  });
  return (
    <MuiRichText content={props.content} extensions={extensions} />
  );
}

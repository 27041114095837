import React, { useState } from 'react';
import {
  Switch as MaterialSwitch, FormControlLabel, FormHelperText, Tooltip, Box,
  Chip as MuiChip, useMediaQuery, Link as MuiLink, TablePagination,
  InputAdornment, IconButton, OutlinedInput, Rating, Autocomplete, TextField,
  Snackbar, Alert, Slide,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingButton as MuiButton } from '@mui/lab';
import { Link as RouteLink, useParams } from 'react-router-dom';
import {
  Help as HelpIcon, VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { DateTimePicker as Picker } from '@mui/x-date-pickers/DateTimePicker';
import { isValid, format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export function DatePicker(props) {
  const {
    value,
    onChange,
    error,
    maxDate,
    minDate,
    name,
    isDisabled,
  } = props;

  const onLocalChange = (newValue) => {
    let formatValue = newValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd');
    }
    onChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <MuiDatePicker
        openTo="day"
        maxDate={maxDate}
        minDate={minDate}
        views={['year', 'month', 'day']}
        value={fnsValue}
        onChange={onLocalChange}
        disabled={isDisabled}
        slotProps={{ textField: { variant: 'outlined', error } }}
        format="yyyy/MM/dd"
      />
    </LocalizationProvider>
  );
}

export function FormSwitch(props) {
  const {
    onChange, checked, label, name,
  } = props;

  return (
    <FormControlLabel
      {...props}
      control={(
        <MaterialSwitch
          checked={checked}
          onChange={(e) => onChange({ target: { name, value: e.target.checked } })}
        />
      )}
      label={label}
    />
  );
}

export function FormErrorText(props) {
  const { children } = props;

  return (
    <FormHelperText error style={{ fontSize: '0.9rem', margin: 0 }}>{children}</FormHelperText>
  );
}

export function FormTitle({
  title, isRequired = false, attention, isNonFlex,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{ fontWeight: 'bold', flexGrow: (isNonFlex || isMobile) ? null : 1 }}
      display="flex"
    >
      <Box justifyContent="center" display="flex" mb={isNonFlex ? 1 : 0}>
        {title}
        {attention && (
          <Tooltip title={`${attention}が検索できます。複数のワードを検索する場合は、スペース区切りで入力してください。`}>
            <HelpIcon />
          </Tooltip>
        )}
      </Box>
      {isRequired && (
        <MuiChip
          label="必須"
          size="small"
          sx={{
            color: '#fff',
            backgroundColor: '#f50057',
            borderRadius: 0,
            marginLeft: (isNonFlex || isMobile) ? '5px' : 'auto',
          }}
        />
      )}
    </Box>
  );
}

export function Link({ children, to, target }) {
  return (
    <MuiLink
      component={RouteLink}
      to={to}
      target={target}
      sx={{
        color: '#1E90FF',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {children}
    </MuiLink>
  );
}

export function LoadingButton(props) {
  const { onClick, children } = props;
  const loading = useSelector((state) => state.commonStore.isLoading);

  return (
    <MuiButton onClick={onClick} loading={loading} {...props}>
      {children}
    </MuiButton>
  );
}

export function Pagination(props) {
  const {
    totalElements,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    isShort,
  } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <TablePagination
      rowsPerPageOptions={[50, 100, 150, 200]}
      component="div"
      count={totalElements}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      labelRowsPerPage={isShort || isMobile ? '表示数：' : '1ページの表示件数：'}
      labelDisplayedRows={({ from, to, count }) => (isShort || isMobile ? `全${count}件` : `全${count}件中 ${from}件から ${to}件までを表示中`)}
    />
  );
}

export function PasswordInput(props) {
  const {
    name, value, onChange, error,
  } = props;
  const [showPassword, setShowPassword] = useState(true);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <OutlinedInput
      fullWidth
      type={showPassword ? 'password' : 'text'}
      value={value}
      name={name}
      error={error}
      inputProps={{ maxLength: 16 }}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      )}
      autoComplete="new-password"
      onChange={onChange}
    />
  );
}

export function RatingStar(props) {
  const {
    name,
    value,
    onChange,
    readOnly,
  } = props;

  return (
    <Rating
      name="rate"
      value={value}
      precision={1}
      onChange={(_, newValue) => { onChange(name, newValue); }}
      size="large"
      readOnly={readOnly}
    />
  );
}

export function SearchSelectBox(props) {
  const {
    options,
    error,
    value,
    onChange,
    placeholder,
    disableClearable,
    isDisabled,
    label,
    name,
  } = props;

  const targetValues = options.filter((row) => row.id === value);
  let [targetValue] = targetValues;
  targetValue = targetValue || null;

  return (
    <Autocomplete
      style={{ minWidth: '200px' }}
      size="small"
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, valueObj) => option.id === valueObj.id}
      renderInput={(params) => <TextField {...params} autoComplete="off" variant="outlined" error={error} label={label} placeholder={placeholder || '選択してください'} />}
      value={targetValue}
      onChange={(_, selectValue) => {
        onChange({
          target: {
            name,
            value: selectValue && selectValue.id,
          },
        });
      }}
      disabled={isDisabled}
      disableClearable={disableClearable}
      noOptionsText="データがありません"
    />
  );
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export function SuccessSnackbar(props) {
  const { open, onClose, message } = props;

  const handleCloseCheck = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseCheck}
      TransitionComponent={TransitionUp}
    >
      <Alert
        elevation={10}
        variant="filled"
        onClose={handleCloseCheck}
        severity="success"
        action={(
          <IconButton aria-label="delete" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
          )}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export function TabPanel(props) {
  const { children, value, index } = props;

  if (value !== index) {
    return null;
  }
  return children;
}

export function withParams(Component) {
  function WithParams(props) {
    return <Component {...props} params={useParams()} />;
  }
  return WithParams;
}

export function TextArea({
  value, onChange, name, isDisabled, error, minRows,
}) {
  const onLocalChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.value,
      },
    });
  };

  return (
    <Box
      component="textarea"
      disabled={isDisabled}
      name={name}
      value={value || ''}
      onChange={onLocalChange}
      rows={minRows || 5}
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        fontFamily: "'IBM Plex Sans', sans-serif",
        fontSize: 'inherit',
        fontWeight: 400,
        lineHeight: 1.5,
        padding: '8px 12px',
        borderRadius: '8px',
        color: '#000',
        background: '#fff',
        border: error ? '1px solid red' : '1px solid rgba(0, 0, 0, 0.23)',
        '&:hover': {
          borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.87)',
        },
        '&:focus': {
          border: error ? '2px solid red' : '2px solid rgb(30,144,255)',
        },
        '&:focus-visible': {
          outline: 0,
        },
      }}
    />
  );
}

export function DateTimePicker(props) {
  const {
    value,
    onChange,
    name,
  } = props;

  const onLocalChange = (newValue) => {
    let formatValue = newValue;
    if (isValid(formatValue)) {
      formatValue = format(formatValue, 'yyyy/MM/dd HH:mm:00');
    }
    onChange({
      target: {
        name,
        value: formatValue,
      },
    });
  };

  const fnsValue = value ? new Date(value) : null;

  return (
    <LocalizationProvider
      adapterLocale={ja}
      dateAdapter={AdapterDateFns}
    >
      <Picker
        format="yyyy/MM/dd HH:mm"
        value={fnsValue}
        onChange={onLocalChange}
        slotProps={{ textField: { variant: 'outlined' } }}
      />
    </LocalizationProvider>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { Box, TextField } from '@mui/material';
import { loginUserActions } from '../../../redux/systemSetting/loginUser/loginUserState';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import { FormTitle, SuccessSnackbar } from '../../../components/atoms/Base';
import DataTable from './dataTable/DataTable';
import detailUrl from '../../../constants/frontUrls';
import DataSelectList from '../../../components/atoms/DataSelectList';

const DEFAULT_SEARCH_CONDITION = {
  loginUserName: '',
  email: '',
  shopId: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

class LoginUserList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'name', label: '名前', sortId: 'name' },
      { id: 'shopName', label: '所属店舗', sortId: 'shop_name' },
      { id: 'email', label: 'メールアドレス', sortId: 'email' },
    ];

    let searchCondition = DEFAULT_SEARCH_CONDITION;

    if (props.loginUserInfo.searchCondition) {
      searchCondition = props.loginUserInfo.searchCondition;
    }
    this.state = {
      accountId: props.loginUser.userInfo.accountId,
      shopId: props.loginUser.userInfo.shopId,
      datas: {},
      searchCondition,
      isSuccessOpen: false,
      successMessage: '',
    };
    // ログインユーザー取得処理
    this.props.dispatch(loginUserActions.getDatas(searchCondition));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loginUserInfo.datas !== this.props.loginUserInfo.datas) {
      if (this.props.loginUserInfo.datas) {
        this.setDatas(this.props.loginUserInfo.datas);
      }
    }
    if (prevProps.loginUserInfo.isDeleteSuccess
      !== this.props.loginUserInfo.isDeleteSuccess) {
      if (this.props.loginUserInfo.isDeleteSuccess) {
        this.setSuccess('削除しました。');
      }
    }
  }

  setSuccess(successMessage) {
    this.onSearch();
    this.setState({ isSuccessOpen: true, successMessage });
  }

  search = (searchCondition) => {
    this.props.dispatch(loginUserActions.setSearchCondition(searchCondition));
    this.props.dispatch(loginUserActions.getDatas(searchCondition));
  };

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      [name]: value,
    };
    this.setState({ searchCondition: tempCondition });
  };

  onSnackbarClose = () => {
    this.setState({ isSuccessOpen: false });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onDelete = (ids) => {
    this.props.dispatch(loginUserActions.deleteDatas({ ids }));
  };

  render() {
    const {
      accountId,
      shopId,
      datas,
      searchCondition,
      isSuccessOpen,
      successMessage,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          condition={searchCondition}
          targetScreen="login-user"
          reSearch={this.reSearch}
        >
          <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
            <Box mr={1}>
              <FormTitle title="名前" />
              <TextField value={searchCondition.loginUserName} fullWidth name="loginUserName" onChange={this.onChange} />
            </Box>
            <Box mr={1}>
              <FormTitle title="メールアドレス" />
              <TextField value={searchCondition.email} fullWidth name="email" onChange={this.onChange} />
            </Box>
            {!shopId && (
              <Box>
                <FormTitle title="店舗" />
                <DataSelectList
                  name="shopId"
                  target="shops"
                  parameter={accountId ? { accountId } : null}
                  value={searchCondition.shopId}
                  onChange={this.onChange}
                />
              </Box>
            )}
          </Box>
        </SearchBox>
        <DataTable
          url={detailUrl.LOGIN_USER_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          procName="ログインユーザー"
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements || 0}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onDelete={this.onDelete}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />

        <SuccessSnackbar
          open={isSuccessOpen}
          onClose={this.onSnackbarClose}
          message={successMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUserInfo: state.loginUserStore,
  loginUser: state.loginStore,
});

export default connect(mapStateToProps)(LoginUserList);

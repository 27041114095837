import React from 'react';
import { connect } from 'react-redux';
import { TextField, Box } from '@mui/material';
import SearchBox from '../../../components/templates/searchBox/SearchBox';
import DataTable from '../../../components/templates/dataTable/DataTable';
import detailUrl from '../../../constants/frontUrls';
import { shopActions } from '../../../redux/systemSetting/shop/shopState';
import { FormTitle } from '../../../components/atoms/Base';

const DEFAULT_SEARCH_CONDITION = {
  shopName: '',
  page: 0,
  records: 50,
  order: 'asc',
  orderBy: '',
};

class ShopList extends React.Component {
  constructor(props) {
    super(props);

    this.headCells = [
      { id: 'shopCode', label: '店舗コード', sortId: 'shop_code' },
      { id: 'displayOrder', label: '表示順', sortId: 'display_order' },
      { id: 'prefectureName', label: '都道府県', sortId: 'prefecture_name' },
      { id: 'cityName', label: '市区町村', sortId: 'city_name' },
      { id: 'name', label: '店舗名', sortId: 'name' },
      { id: 'phone', label: '電話番号', sortId: 'phone' },
      { id: 'email', label: 'メール', sortId: 'email' },
      { id: 'published', label: '公開状況', sortId: 'published' },
    ];

    const searchCondition = DEFAULT_SEARCH_CONDITION;
    this.search(searchCondition);
    this.state = {
      datas: {},
      searchCondition,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopInfo.datas !== this.props.shopInfo.datas) {
      if (this.props.shopInfo.datas) {
        this.setDatas(this.props.shopInfo.datas);
      }
    }
  }

  setDatas = (datas) => {
    this.setState({ datas });
  };

  onSearch = () => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  search = (searchCondition) => {
    this.props.dispatch(shopActions.setSearchCondition(searchCondition));
    this.props.dispatch(shopActions.getDatas(searchCondition));
  };

  onClear = () => {
    this.setState({ searchCondition: DEFAULT_SEARCH_CONDITION });
    this.props.dispatch(shopActions.setSearchCondition(DEFAULT_SEARCH_CONDITION));
  };

  onChange = (event) => {
    const { name, value } = event.target;
    const { searchCondition } = this.state;
    this.setState({
      searchCondition: {
        ...searchCondition,
        [name]: value,
      },
    });
  };

  onChangePage = (_, newPage) => {
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: newPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  onChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      records: rowsPerPage,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  handleRequestSort = (_, property) => {
    const { order, orderBy } = property;
    const { searchCondition } = this.state;
    const tempCondition = {
      ...searchCondition,
      page: 0,
      orderBy,
      order,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  reSearch = (searchCondition) => {
    const tempCondition = {
      ...searchCondition,
      page: 0,
    };
    this.setState({ searchCondition: tempCondition });
    this.search(tempCondition);
  };

  render() {
    const {
      datas,
      searchCondition,
    } = this.state;

    return (
      <>
        <SearchBox
          onSearch={this.onSearch}
          onClear={this.onClear}
          condition={searchCondition}
          targetScreen="shop"
          reSearch={this.reSearch}
        >
          <Box>
            <FormTitle title="店舗名" />
            <TextField fullWidth size="small" value={searchCondition.shopName} name="shopName" onChange={this.onChange} />
          </Box>
        </SearchBox>
        <DataTable
          url={detailUrl.SHOP_DETAIL}
          rows={datas.content || []}
          headCells={this.headCells}
          page={searchCondition.page}
          rowsPerPage={searchCondition.records}
          totalElements={datas.totalElements || 0}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          order={searchCondition.order}
          orderBy={searchCondition.orderBy}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginUser: state.loginStore,
  shopInfo: state.shopStore,
});

export default connect(mapStateToProps)(ShopList);

import React, { useState, useEffect, useCallback } from 'react';
import {
  ImageList as MuiImageList, ImageListItem, Box, Collapse, Button, IconButton,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '../DeleteDialog';
import FileUploadDialog from './FileUploadDialog';

export default function PublicImageList(props) {
  const {
    itemDatas, isEdit, onAddImg, onDelete, isNeedIntroduction, onChange, addLimitNumber,
  } = props;
  const [hoverData, setHoverData] = useState({ hover: false, selectedId: null });
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({ open: false, selectedId: null });
  const [maxId, setMaxId] = useState(false);
  const [tempArray, setTempArray] = useState([]);
  const [selectItem, setSelectItem] = useState(null);

  useEffect(() => {
    if (itemDatas) {
      setTempArray([...itemDatas]);
    }
  }, [itemDatas]);

  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }, []);

  const onDragEnd = useCallback((result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const arrays = reorder(
      tempArray,
      result.source.index,
      result.destination.index,
    );
    const updatedArrays = arrays.map((obj, index) => ({
      ...obj,
      displayOrder: index + 1,
    }));

    setTempArray(updatedArrays);
    onChange(updatedArrays);
  }, [tempArray, reorder, onChange]);

  const imageListArea = () => (isEdit ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="list"
        direction="horizontal"
      >
        {(provide) => (
          <MuiImageList
            sx={{ overflowY: 'hidden' }}
            cols={3}
            ref={provide.innerRef}
            {...provide.droppableProps}
          >
            {tempArray.map((item, index) => (

              <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <ImageListItem
                    key={item.id}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    name={index}
                  >

                    <img src={item.isNew ? URL.createObjectURL(item[0]) : process.env.REACT_APP_AWS_PUBLIC_URL + item.imagePath} alt={item.title} style={{ width: '100%' }} title="viewer" />
                    <Collapse
                      in={hoverData.hover && hoverData.selectedId === item.id}
                      collapsedSize={40}
                      sx={{
                        minHeight: '50px',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        cursor: isEdit ? 'pointer' : '',
                        bottom: 0,
                        color: '#fff',
                        width: '100%',
                        background: 'rgba(0, 0, 0, 0.5)',
                        padding: '5px',
                      }}
                      onMouseEnter={() => setHoverData({ hover: true, selectedId: item.id })}
                      onMouseLeave={() => setHoverData({ hover: false, selectedId: null })}
                    />

                    <IconButton
                      size="medium"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        zIndex: 99,
                        color: '#fff',
                      }}
                      onClick={() => setDeleteData({ open: true, selectedId: item.id })}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>

                  </ImageListItem>
                )}
              </Draggable>
            ))}
            {provide.placeholder}
          </MuiImageList>
        )}

      </Droppable>
    </DragDropContext>
  )
    : <StaticImageList items={tempArray} />);

  return (
    <>
      {isEdit && (!addLimitNumber || (addLimitNumber && tempArray.length < addLimitNumber)) && (
        <Box textAlign="right">
          <Button variant="contained" size="small" onClick={() => { setOpen(true); setSelectItem(null); setMaxId(tempArray.length > 0 ? Math.max(...itemDatas.map((item) => item.id)) + 1 : 1); }}>アップロード</Button>
        </Box>
      )}
      {imageListArea()}
      <DeleteDialog
        open={deleteData.open}
        onClose={() => setDeleteData({ open: false, selectedId: null })}
        onDelete={() => {
          setDeleteData({ open: false, selectedId: null }); onDelete(deleteData.selectedId);
        }}
        procName="画像"
      />
      <FileUploadDialog
        isOpen={open}
        isNeedIntroduction={isNeedIntroduction}
        item={selectItem}
        newId={maxId}
        onClose={() => { setOpen(false); setSelectItem(null); }}
        onClick={(data) => { setOpen(false); onAddImg(data); setSelectItem(null); }}
      />
    </>
  );
}

function StaticImageList({ items }) {
  return (
    <MuiImageList sx={{ overflowY: 'hidden' }} cols={3}>
      {items.map((item, index) => (
        <ImageListItem key={item.id} name={index}>
          <img srcSet={item.isNew ? URL.createObjectURL(item[0]) : process.env.REACT_APP_AWS_PUBLIC_URL + item.imagePath} src={item.imagePath} alt={item.title} loading="lazy" />
          {(item.title) && (
            <Collapse
              in
              collapsedSize={40}
              sx={{
                minHeight: '50px',
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                color: '#fff',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.5)',
                padding: '5px',
              }}
            >
              <Box>{item.title}</Box>
            </Collapse>
          )}
        </ImageListItem>
      ))}
    </MuiImageList>
  );
}

import React from 'react';
import {
  DialogContent, DialogActions,
  Box, Button, LinearProgress, Chip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../components/atoms/Dialog';
import { homeActions } from '../../redux/home/homeState';
import RichTextReadOnly from '../../components/templates/richTextReadOnly/RichTextReadOnly';

export default function NoticeDetailDialog(props) {
  const { isOpen, id, onClose } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(false);
  const dbData = useSelector((state) => state.homeStore.notice);

  React.useEffect(() => {
    if (isOpen) {
      setLoading(true);
      dispatch(homeActions.getNoticeId({ id }));
    }
  }, [isOpen, id]);
  React.useEffect(() => {
    if (dbData) {
      setLoading(false);
      setData(dbData);
    }
  }, [dbData]);

  return (
    <Dialog
      title="お知らせ"
      isOpen={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      {loading && (<LinearProgress color="secondary" />)}
      {!loading && (
        <DialogContent dividers>
          <Chip label={data.category} variant="outlined" />
          <Box ml={1}>{data.title}</Box>
          <Box sx={{ textAlign: 'right' }}>{data.startDate}</Box>
          <RichTextReadOnly content={data.content} />
        </DialogContent>
      )}

      <DialogActions>
        <Button color="inherit" onClick={onClose}>閉じる</Button>
      </DialogActions>

    </Dialog>
  );
}

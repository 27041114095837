import React, { useState, useEffect } from 'react';
import {
  ImageList as MuiImageList, ImageListItem,
} from '@mui/material';

export default function ImageList(props) {
  const {
    itemDatas,
  } = props;
  const [tempArray, setTempArray] = useState([]);

  useEffect(() => {
    if (itemDatas) {
      setTempArray([...itemDatas]);
    }
  }, [itemDatas]);

  return (
    <MuiImageList sx={{ overflowY: 'hidden' }} cols={3}>
      {tempArray.map((item, index) => (
        <ImageListItem key={item.id} name={index}>
          <img srcSet={item.imagePath} src={item.imagePath} alt={item.title} loading="lazy" />
        </ImageListItem>
      ))}
    </MuiImageList>
  );
}

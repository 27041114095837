const ROOT_URL = 'https://api.catering-selection.jp';

export const REST_URL = `${ROOT_URL}/shop`;

export const REST_API = {
  COMMON: {
    LOGIN: `${REST_URL}/authenticate`,
    LOGIN_INFO: `${REST_URL}/login-info`,
    RESET_PASSWORD: `${REST_URL}/reset-password`,
  },
  SELECT: {
    CODE: `${REST_URL}/select/codes`,
    SHOP: `${REST_URL}/select/shops`,
    USER: `${REST_URL}/select/users`,
  },
  CONDITION: {
    CONDITION: `${ROOT_URL}/common/conditions`,
    CONDITION_ID: `${ROOT_URL}/common/conditions/{id}`,
  },
  HOME: {
    TODO: `${REST_URL}/todos`,
    NOTICE: `${REST_URL}/notices`,
    NOTICE_ID: `${REST_URL}/notices/{id}`,
  },
  OPPORTUNITY: {
    OPPORTUNITY: {
      CHECK_LIST: `${REST_URL}/checks`,
      OPPORTUNITY: `${REST_URL}/opportunities`,
      OPPORTUNITY_CALENDAR: `${REST_URL}/opportunities/calendar`,
      OPPORTUNITY_ID: `${REST_URL}/opportunities/{id}`,
      ANSWER: `${REST_URL}/opportunities/answer`,
      OPPORTUNITY_ORDER: `${REST_URL}/opportunities/order-details`,
      OPPORTUNITY_ESTIMATE: `${REST_URL}/opportunities/estimate-details`,
      REPORT: `${REST_URL}/opportunities/reports`,
      DELIVERYS: `${REST_URL}/opportunities/delivery-files`,
    },
    FILE: {
      FILE: `${REST_URL}/opportunities/file`,
      FILES: `${REST_URL}/opportunities/files`,
      FILES_ID: `${REST_URL}/opportunities/files/{id}`,
    },
    ESTIMATE: {
      ESTIMATE_ID: `${REST_URL}/estimates/{id}`,
      ESTIMATE_DETAIL: `${REST_URL}/estimate-details`,
    },
  },
  SYSTEM_SETTING: {
    LOGIN_USER: {
      LOGIN_USER: `${REST_URL}/login-users`,
      LOGIN_USER_CSV: `${REST_URL}/login-users/csv`,
      LOGIN_USER_CHANGE_PASSWORD: `${REST_URL}/login-users/change-password`,
      LOGIN_USER_ID: `${REST_URL}/login-users/{id}`,
    },
    SHOP: {
      SHOP: `${REST_URL}/shops`,
      SHOP_ID: `${REST_URL}/shops/{id}`,
    },
    SHOP_ATTACHED: {
      HOLIDAY: `${REST_URL}/shops/holidays`,
      HOLIDAY_ID: `${REST_URL}/shops/holidays/{id}`,
    },
    PLAN: {
      PLAN: `${REST_URL}/plans`,
    },
    REVIEW: {
      REVIEW: `${REST_URL}/reviews`,
      REVIEW_ID: `${REST_URL}/reviews/{id}`,
    },
    ILLUSTRATION: {
      ILLUSTRATION: `${REST_URL}/illustrations`,
      ILLUSTRATION_ID: `${REST_URL}/illustrations/{id}`,
    },
  },
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Paper,
} from '@mui/material';
import { illustrationActions } from '../../../redux/systemSetting/illustration/illustrationState';
import PublicImageList from '../../../components/templates/publicImageList/PublicImageList';
import ConfirmDialog from './ConfirmDialog';

const DEFAULT_FORM = {
  id: null,
  images: [],
};

export default function IllustrationDetailInfo(props) {
  const { opportunityId } = props;
  const dispatch = useDispatch();
  const dbData = useSelector((state) => state.illustrationStore.data);
  const [isEdit, setEdit] = useState(!opportunityId);
  const [data, setData] = useState(DEFAULT_FORM);
  const [isCompDialogOpen, setCompDialogOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [tempImageList, setTempImageList] = useState([]);
  const isSaveSuccess = useSelector((state) => state.illustrationStore.isSaveSuccess);
  const isCompImgUploadSuccess = useSelector((state) => (
    state.illustrationStore.isCompImgUploadSuccess
  ));

  const search = () => {
    dispatch(illustrationActions.getData({ id: opportunityId }));
  };

  React.useEffect(() => {
    if (opportunityId) {
      search();
    }
  }, [opportunityId]);

  React.useEffect(() => {
    if (isSaveSuccess) {
      search();
    }
  }, [isSaveSuccess]);

  React.useEffect(() => {
    if (isCompImgUploadSuccess) {
      search();
    }
  }, [isCompImgUploadSuccess]);

  React.useEffect(() => {
    if (opportunityId && dbData && dbData.data) {
      setData(dbData.data);
      setImageList(dbData.images);
      setTempImageList(dbData.images);
    }
  }, [dbData]);

  const onEdit = () => {
    search();
    setEdit(true);
  };

  const onClose = () => {
    setTempImageList(imageList);
    setEdit(false);
  };

  const getUploadTargetFile = () => {
    const uploadFiles = [];
    tempImageList.forEach((img) => {
      if (img.isNew) {
        uploadFiles.push(img[0]);
      }
    });
    return uploadFiles;
  };

  const onSave = () => {
    const uploadImages = getUploadTargetFile();
    const saveForm = {
      illustrationId: data.id,
      imgForm: { images: tempImageList },
      imageFiles: uploadImages,
    };
    dispatch(illustrationActions.saveData(saveForm));
    onClose();
  };

  const addImg = (value) => {
    let imgArray = [];
    const exists = tempImageList.some((img) => img.id === value.id);

    if (exists) {
      imgArray = tempImageList.map((img) => (img.id === value.id ? value : img));
    } else {
      const inputVal = { ...value, displayOrder: tempImageList.length + 1 };
      imgArray = [...tempImageList, inputVal];
    }

    setTempImageList(imgArray);
  };

  const deleteImg = (deleteId) => {
    const imgArray = tempImageList.filter((img) => img.id !== deleteId);
    setTempImageList(imgArray);
  };

  const onImgUploadComp = () => {
    setCompDialogOpen(true);
  };

  const onCompImgUpload = () => {
    dispatch(illustrationActions.compImgUpload({ id: data.id }));
    setCompDialogOpen(false);
  };

  const onCloseComfirmDialog = () => {
    setCompDialogOpen(false);
  };

  return (
    <>
      {!data.id && (
        <Paper style={{ padding: '10px' }} elevation={3}>
          <Box style={{ textAlign: 'center' }}>
            データがありません
          </Box>
        </Paper>
      )}
      {data.id && (
        <>
          {data && !data.imageUploadCompleted && (
            <Box
              mr={1}
              style={{
                minHeight: '48px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
              }}
            >
              {!isEdit && tempImageList.length > 0 && (
                <Button variant="contained" size="small" onClick={onImgUploadComp} style={{ marginRight: '5px' }}>画像アップロード完了</Button>
              )}
              {!isEdit && (
                <Button variant="contained" size="small" onClick={onEdit}>画像の追加</Button>
              )}
            </Box>
          )}

          <Paper elevation={3} style={{ padding: '10px' }}>
            <PublicImageList
              itemDatas={tempImageList}
              isEdit={isEdit}
              onAddImg={addImg}
              onDelete={deleteImg}
              onChange={(e) => setTempImageList(e)}
            />

            {isEdit && (
              <Box position="sticky" bottom={0} left={0} textAlign="center" width="100%" p={1} backgroundColor="#fff" borderTop="1px solid rgba(0, 0, 0, .125)">
                <Button color="inherit" variant="contained" size="small" onClick={onClose} style={{ marginRight: '10px' }}>キャンセル</Button>
                <Button variant="contained" size="small" onClick={onSave}>保存</Button>
              </Box>
            )}
          </Paper>
        </>
      )}
      <ConfirmDialog
        onClick={onCompImgUpload}
        open={isCompDialogOpen}
        onClose={onCloseComfirmDialog}
      />
    </>
  );
}
